
import { useEffect } from "react";
import ResetPasswordForm from "./component/ResetPasswordForm";
import { useNavigate, useParams } from "react-router";
import Error from "../Error/Error";
import { jwtDecode } from "jwt-decode";
import ErrorTokenExpired from "../Error/components/ErrorTokenExpired";

export default function ResetPassword() {
  const { token } = useParams();
  const navegate = useNavigate();
  var decoded;
 
  console.log();

  function isTokenFormat(str) {
    // Define a regular expression for a basic token format
    const tokenRegex = /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_.+/=]+$/;
  
    // Test the string against the regular expression
    return tokenRegex.test(str);
  }
  
  // Example usage
  const isToken = isTokenFormat(token);
  
if (isToken) {
  decoded = jwtDecode(token);
} else {
  decoded = null
}
  
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
    return <>
    {decoded != null?  
    <>
    {decoded.exp > Date.now() / 1000 ?  <ResetPasswordForm token={token} /> : <ErrorTokenExpired />}
    </>
     : <Error />}
  
         
       
    </>
};
