import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

//image
import avatarMain from "../../../Images/avatar/avatar-main.png";
import profile_01 from "../../../Images/profile/1.jpg";
import coursefilter_01 from "../../../Images/course-filter/1.jpg";
import { CheckLogin } from "../../../store/AuthIsLoginSlicer";
import { Link, useLocation, useParams } from "react-router-dom";
import IMG from "../../../component/ui/IMG";
import { getStudentCourseApi } from "../../../store/getStudentCoursesSlicer";
import { getCourse } from "../../../store/getCourseByIdSlicer";

export default function ProfileComponent(props) {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);
  const { loading } = useSelector((state) => state.getStudentCourses);
  const dispatch = useDispatch();
  const [studentCourse, setStudentCoures] = useState([]);
  const getStudentCourses = () => {
    dispatch(getStudentCourseApi()).then((result) => {
      if (result.payload.data != []) {
        setStudentCoures(result.payload.data);
      }
      console.log(studentCourse);

      // if (result.payload.data.length > 0) {
      //   result.payload.data.map((item) => {
      //     if (item !== null) {
      //       dispatch(getCourse(item.course_id)).then((res) => {
      //         if (res.payload.message === "fetched successfully") {
      //           setStudentCoures((studentCourse) => [
      //             ...studentCourse,
      //             res.payload.data,
      //           ]);
      //         }
      //       });
      //     }
      //   });
      //   console.log(studentCourse);
      // }
    });
  };

  useEffect(() => {
    getStudentCourses();
  }, []);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);

  function ProfileComponentArabic({ userdata }) {
    return (
      <>
        <div className="profile-top back__course__area pt---150 pb---90">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                {/* <img src={userData.img} alt={userData.name} /> */}
                {userdata.image && userdata.image != " " ? (
                  <IMG src={userdata.image} title={userdata.name} />
                ) : (
                  <img src={avatarMain} alt="profile" />
                )}
              </div>
              <div className="col-lg-8">
                {/* <UserProfile userData={userProfile.userData} /> */}

                <ul className="user-section">
                  <li className="user">
                    <span className="name">الاسم:</span>
                    <b> {userdata.name}</b>
                  </li>
                  <li>
                    المهنة :<b> طالب </b>
                  </li>
                  <li>
                    تلفون:<b> {userdata.mobile}</b>
                  </li>
                  <li>
                    بريد الكتروني :<b> {userdata.email}</b>
                  </li>
                </ul>
                <div className="react-course-filter related__course">
                  <h4 className="teacher__course ">كورساتي </h4>
                  {studentCourse&&<>
                    {studentCourse !=[]? (
                      <>
                        {studentCourse.map((item, index) => {
                         
                          return (
                            <>
                              <div className="row react-grid" key={index}>
                                <div className="single-studies col-lg-6 grid-item">
                                  <div className="inner-course">
                                    <div className="case-img">
                                      {/* <a href="#" className="cate-w">
                          فضاء
                        </a> */}
                                      {item.image &&
                                      item.image != "" ? (
                                        <IMG
                                          src={item.image}
                                          title={item.name.en}
                                        />
                                      ) : (
                                        <img src={avatarMain} alt="profile" />
                                      )}
                                    </div>
                                    <div className="case-content">
                                      <h4 className="case-title">
                                        {" "}
                                        <Link
                                          to={`/lesson/${item._id}`}
                                        >
                                          {item.name.ar}
                                        </Link>
                                      </h4>

                                      {/* <ul className="react-ratings">
                                    <li className="react-book">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-file-text"
                                      >
                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                                        <polyline points="14 2 14 8 20 8" />
                                        <line
                                          x1={16}
                                          y1={13}
                                          x2={8}
                                          y2={13}
                                        />
                                        <line
                                          x1={16}
                                          y1={17}
                                          x2={8}
                                          y2={17}
                                        />
                                        <polyline points="10 9 9 9 8 9" />
                                      </svg>{" "}
                                      20 درس
                                    </li>
                                  </ul> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    ): (
                      <>
                        <h4>لا يوجد كورسات  </h4>
                      </>
                    )}
                    </>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function ProfileComponentEnglish({ userdata }) {
    return (
      <>
        <div className="profile-top back__course__area pt---150 pb---90">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                {/* <img src={userData.img} alt={userData.name} /> */}

                {userdata.image && userdata.image != " " ? (
                  <IMG src={userdata.image} title={userdata.name} />
                ) : (
                  <img src={avatarMain} alt="profile" />
                )}
              </div>
              <div className="col-lg-8">
                {/* <UserProfile userData={userProfile.userData} /> */}

                <ul className="user-section">
                  <li className="user">
                    <span className="name">Name:</span>
                    <b> {userdata.name}</b>
                  </li>
                  <li>
                    Job Title: <b> Student </b>{" "}
                  </li>
                  <li>
                    Phone:<b>{userdata.mobile}</b>{" "}
                  </li>
                  <li>
                    Email:<b>{userdata.email} </b>{" "}
                  </li>
                </ul>

                <div className="count__area2">
                  <ul className="row">
                    {/* {numbersAndStatic.map(item =>  <CardCountArea key={item.id}  countData={item} />)} */}

                    {/* <li className="col-lg-4">
                      <div className="count__content">
                        <div className="icon">
                          <img src="assets/images/profile/2.png" alt="profile" />
                        </div>
                        <div className="text">
                          <span className="count__content-title counter">28</span><em>k</em>
                          <p>Foreign followers</p> 
                        </div>                                           
                      </div>
                    </li> 
                    <li className="col-lg-4">
                      <div className="count__content">
                        <div className="icon">
                          <img src="assets/images/profile/3.png" alt="profile" />
                        </div>
                        <div className="text">
                          <span className="count__content-title counter">10</span><em>k</em>
                          <p>Classes complete</p> 
                        </div>                                           
                      </div>
                    </li>
                    <li className="col-lg-4">
                      <div className="count__content">
                        <div className="icon">
                          <img src="assets/images/profile/4.png" alt="profile" />
                        </div>
                        <div className="text">
                          <span className="count__content-title counter">208</span><em>k</em>
                          <p> Students enrolled</p>  
                        </div>                                          
                      </div>
                    </li>
                    */}
                  </ul>
                </div>
                <div className="react-course-filter related__course">
                  <h4 className="teacher__course">My Courses</h4>
                  {studentCourse && (
                    <>
                      {studentCourse !=[]? (
                        <>
                      
                          {studentCourse.map((item, index) => {
                            if (item == null) {
                              return;
                            }
                            return (
                              <>
                                <div className="row react-grid" key={index}>
                                  <div className="single-studies col-lg-6 grid-item">
                                    <div className="inner-course">
                                      <div className="case-img">
                                        {/* <a href="#" className="cate-w">
                            فضاء
                          </a> */}
                                        {item.image &&
                                        item.image != "" ? (
                                          <IMG
                                            src={item.image}
                                            title={item.name.en}
                                          />
                                        ) : (
                                          <img src={avatarMain} alt="profile" />
                                        )}
                                      </div>
                                      <div className="case-content">
                                        <h4 className="case-title">
                                          {" "}
                                          <Link
                                            to={`/lesson/${item._id}`}
                                          >
                                            {item.name.en}
                                          </Link>
                                        </h4>

                                        {/* <ul className="react-ratings">
                                      <li className="react-book">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="feather feather-file-text"
                                        >
                                          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                                          <polyline points="14 2 14 8 20 8" />
                                          <line
                                            x1={16}
                                            y1={13}
                                            x2={8}
                                            y2={13}
                                          />
                                          <line
                                            x1={16}
                                            y1={17}
                                            x2={8}
                                            y2={17}
                                          />
                                          <polyline points="10 9 9 9 8 9" />
                                        </svg>{" "}
                                        20 درس
                                      </li>
                                    </ul> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      ): (
                        <>
                          <h4>No Courses Add Yet </h4>
                        </>
                      )}
                    </>
                  ) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      {isArlanguage ? (
        <ProfileComponentArabic userdata={props.userdata} />
      ) : (
        <ProfileComponentEnglish userdata={props.userdata} />
      )}
    </>
  );
}
