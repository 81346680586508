import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  single_01,
  user,
  user2,
  user3,
  user4,
  user5,
} from "../../../Images/course-single/index";
import single from "../../../Images/course-single/2.png";
import { Link, useParams } from "react-router-dom";
import { getCourse } from "../../../store/getCourseByIdSlicer";
import Loader from "../../../component/Loader";
export default function SingleCourseComponent({ data }) {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);

  return (
    <>
      {isArlanguage ? (
        <SingleCourseComponentArabic data={data} />
      ) : (
        <SingleCourseComponentEnglish data={data} />
      )}
    </>
  );
}

function SingleCourseComponentArabic({ data }) {
  return (
    <>
      <div className="course-single pt---90">
        <div className="react-breadcrumbs">
          <div className="breadcrumbs-wrap">
            <div className="custom-breadcrumbs-inner">
              <div className="container">
                <div className="breadcrumbs-text">
                  <h1 className="breadcrumbs-title">{data.course.name.ar}</h1>
                  <ul className="user-section">
                    <li className="user">
                      <span>
                        <img
                          src={`${process.env.REACT_APP_VERSEL_API}/${data.course.image}`}
                          alt={data.course.teacher_id.name}
                        />
                      </span>
                      <span>{data.course.teacher_id.name}</span>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-file-text"
                      >
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                        <polyline points="14 2 14 8 20 8" />
                        <line x1={16} y1={13} x2={8} y2={13} />
                        <line x1={16} y1={17} x2={8} y2={17} />
                        <polyline points="10 9 9 9 8 9" />
                      </svg>
                      {data.lessons.length} درس
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="back__course__page_grid react-courses__single-page pb---16 pt---110">
          <div className="container pb---70">
            <div className="row">
              <div className="col-lg-8">
                <div className="course-single-tab">
                  <ul className="nav nav-tabs" id="back-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="discriptions"
                        data-bs-toggle="tab"
                        href="#discription"
                        role="tab"
                        aria-controls="discription"
                        aria-selected="true"
                      >
                        الوصف
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="curriculums"
                        data-bs-toggle="tab"
                        href="#curriculum"
                        role="tab"
                        aria-controls="curriculum"
                        aria-selected="false"
                      >
                        المقرر
                      </a>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="reviewss"
                        data-bs-toggle="tab"
                        href="#reviews"
                        role="tab"
                        aria-controls="reviews"
                        aria-selected="false"
                      >
                        المراجعات
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="members"
                        data-bs-toggle="tab"
                        href="#member"
                        role="tab"
                        aria-controls="member"
                        aria-selected="false"
                      >
                        اسئلة واجابة
                      </a>
                    </li> */}
                  </ul>
                  <div className="tab-content" id="back-tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="discription"
                      role="tabpanel"
                      aria-labelledby="discription"
                    >
                      <h3>معلومات عن الكورس</h3>
                      <p
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        {data.course.description.ar}
                      </p>
                      <div className="image-banner">
                        <img
                          src={`${process.env.REACT_APP_VERSEL_API}/${data.course.image}`}
                          alt={data.course.teacher_id.name}
                        />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="curriculum"
                      role="tabpanel"
                      aria-labelledby="curriculum"
                    >
                      <h3>المقرر في هذة الدورة</h3>

                      <div className="single-week">
                        <ul className="week__top">
                          <li>الدروس في هذا الكورس</li>
                          <li>{data.lessons.length}</li>
                        </ul>

                        {/* <h3>3 Videos,1 Audio,1 Reading</h3> */}
                        <ul className="course__title">
                          {data.lessons.map((item, index) => {
                            return (
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-video"
                                >
                                  <polygon points="23 7 16 12 23 17 23 7" />
                                  <rect
                                    x={1}
                                    y={5}
                                    width={15}
                                    height={14}
                                    rx={2}
                                    ry={2}
                                  />
                                </svg>

                                <b> {item.name}</b>
                              </li>
                            );
                          })}
                          {/* <li>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-video"
                            >
                              <polygon points="23 7 16 12 23 17 23 7" />
                              <rect
                                x={1}
                                y={5}
                                width={15}
                                height={14}
                                rx={2}
                                ry={2}
                              />
                            </svg>
                            <b>الدرس الاول:</b> مقدمة في علم التسويق
                            <em className="questions">2 اسئلة</em>
                            <em>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-clock"
                              >
                                <circle cx={12} cy={12} r={10} />
                                <polyline points="12 6 12 12 16 14" />
                              </svg>
                              12 دقيقة
                            </em>
                          </li>
                          <li>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-video"
                            >
                              <polygon points="23 7 16 12 23 17 23 7" />
                              <rect
                                x={1}
                                y={5}
                                width={15}
                                height={14}
                                rx={2}
                                ry={2}
                              />
                            </svg>
                            <b>الدرس الثاني:</b> ما هو علم التسويق الرقمي
                            <em>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-clock"
                              >
                                <circle cx={12} cy={12} r={10} />
                                <polyline points="12 6 12 12 16 14" />
                              </svg>
                              26 دقيقة
                            </em>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    {/* <div
                      className="tab-pane fade"
                      id="reviews"
                      role="tabpanel"
                      aria-labelledby="reviews"
                    >
                      <h3>المراجعات </h3>
                      <div className="row mt---40 mb----40">
                        <div className="col-lg-4">
                          <div className="five__number">
                            <em>4.5</em>
                            <div className="back-ratings">
                              <i className="icon_star" />
                              <i className="icon_star" />
                              <i className="icon_star" />
                              <i className="icon_star" />
                              <i className="icon_star" />
                            </div>
                            <h6>(4 تقييم)</h6>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="back-progress-bar">
                            <div className="skillbar-style2">
                              <div className="skill_inners">
                                <span className="skillbar-title">5 نجوم</span>
                                <div className="skillbars green-dark-bg">
                                  <p className="skillbar-bar w-100" />
                                </div>
                                <span>2</span>
                              </div>
                              <div className="skill_inners">
                                <span className="skillbar-title">4 نجوم</span>
                                <div className="skillbars green-dark-bg">
                                  <p className="skillbar-bar w-75" />
                                </div>
                                <span>1</span>
                              </div>
                              <div className="skill_inners">
                                <span className="skillbar-title">3 نجوم</span>
                                <div className="skillbars green-dark-bg">
                                  <p className="skillbar-bar w-50" />
                                </div>
                                <span>1</span>
                              </div>
                              <div className="skill_inners">
                                <span className="skillbar-title">نجمتان</span>
                                <div className="skillbars green-dark-bg">
                                  <p className="skillbar-bar w-25" />
                                </div>
                                <span>0</span>
                              </div>
                              <div className="skill_inners">
                                <span className="skillbar-title">
                                  نجمة واحدة{" "}
                                </span>
                                <div className="skillbars green-dark-bg">
                                  <p className="skillbar-bar" />
                                </div>
                                <span>0</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a href="#" className="post-author">
                        <div className="avatar">
                          <img
                            src={user4}
                            alt="user"
                          />
                        </div>
                        <div className="info">
                          <div className="back-ratings">
                            <i className="icon_star" />
                            <i className="icon_star" />
                            <i className="icon_star" />
                            <i className="icon_star" />
                            <i className="icon_star" />
                          </div>
                          <h4 className="name">
                            احمد ممدوح &nbsp;
                            <span className="designation">8 يوليو ,2023</span>
                          </h4>
                          <p>
                            هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء
                            لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي
                            للنص أو شكل توضع الفقرات في الصفحة
                          </p>
                        </div>
                      </a>
                      <a href="#" className="post-author">
                        <div className="avatar">
                          <img
                            src={user5}
                            alt="user"
                          />
                        </div>
                        <div className="info">
                          <div className="back-ratings">
                            <i className="icon_star" />
                            <i className="icon_star" />
                            <i className="icon_star" />
                            <i className="icon_star" />
                            <i className="icon_star" />
                          </div>
                          <h4 className="name">
                            ياسمين محمد &nbsp;
                            <span className="designation">8 يوليو ,2023</span>
                          </h4>
                          <p>
                            هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء
                            لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي
                            للنص أو شكل توضع الفقرات في الصفحة
                          </p>
                        </div>
                      </a>
                      <div className="blog-form pt---30">
                        <h3>اترك تعليقا </h3>
                        <p className="pb---15">
                          البريد الالكتروني لن يظهر للجيع.
                          <br />
                          الحقول ذات النجمة مطلوبة
                        </p>
                        <form id="contact-form">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="back-input">
                                <input
                                  id="name"
                                  type="text"
                                  name="name"
                                  placeholder="الاسم "
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 pdl-5">
                              <div className="back-input">
                                <input
                                  id="email"
                                  type="email"
                                  name="email"
                                  placeholder="البريد الالكتروني"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="back-input">
                                <input
                                  id="subject"
                                  type="text"
                                  name="subject"
                                  placeholder="الموضوع"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="back-ratings">
                                <b>التقييم : </b>
                                <i className="icon_star" />
                                <i className="icon_star" />
                                <i className="icon_star" />
                                <i className="icon_star" />
                                <i className="icon_star" />
                              </div>
                              <div className="back-textarea">
                                <textarea
                                  id="message"
                                  name="message"
                                  placeholder="أترك رسالة"
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <button
                                type="submit"
                                className="back-btn bgclr-red"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-arrow-right"
                                >
                                  <line x1={5} y1={12} x2={19} y2={12} />
                                  <polyline points="12 5 19 12 12 19" />
                                </svg>
                                ارسال المراجعة
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div> */}
                    {/* <div
                      className="tab-pane fade"
                      id="member"
                      role="tabpanel"
                      aria-labelledby="member"
                    >
                      <h3>اسئلة متكررة </h3>
                      <p>
                        هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء
                        لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص
                        أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم
                        استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ
                        -إلى حد ما-
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 md-mt-60">
                <div className="react-sidebar react-back-course2 ml----30">
                  <div className="widget get-back-course">
                    <ul className="price">
                      <li className="clr-red">
                        السعر : {data.course.price / 100} جنيها{" "}
                      </li>
                      {/* <li>$ 95 USD</li>
                      <li>25% OFF</li> */}
                    </ul>
                    <ul className="price__course">
                      <li>
                        <i className="icon_profile" />
                        المدرس
                        <b>{data.course.teacher_id.name}</b>
                      </li>
                      {/* <li>
                        <i className="icon_tag_alt" /> الموضوع
                        <b>التسويق</b>
                      </li> */}
                      {/* <li>
                        <i className="icon_group" /> مسجل
                        <b>146 طالب</b>
                      </li> */}
                      {/* <li>
                        <i className="icon_clock_alt" /> االمدة
                        <b>اسبوع واحد</b>
                      </li> */}
                      <li>
                        <i className="icon_book_alt" /> الدروس
                        <b>{data.lessons.length} درس</b>
                      </li>
                      <li>
                        <i className="icon_map_alt" /> اللغة <b> عربي</b>
                      </li>
                    </ul>
                    <Link
                      to={`/cart/${data.course._id}`}
                      className="start-btn bgclr-red"
                    >
                      شراء الان
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-arrow-right"
                      >
                        <line x1={5} y1={12} x2={19} y2={12} />
                        <polyline points="12 5 19 12 12 19" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SingleCourseComponentEnglish({ data }) {
  return (
    <>
      <div className="course-single pt---90">
        <div className="react-breadcrumbs">
          <div className="breadcrumbs-wrap">
            <div className="custom-breadcrumbs-inner">
              <div className="container">
                <div className="breadcrumbs-text">
                  <h1 className="breadcrumbs-title">{data.course.name.en}</h1>
                  <ul className="user-section">
                    <li className="user">
                      <span>
                        <img
                          src={`${process.env.REACT_APP_VERSEL_API}/${data.course.image}`}
                          alt={data.course.teacher_id.name}
                        />
                      </span>
                      <span>{data.course.teacher_id.name}</span>
                    </li>
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-file-text"
                      >
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                        <polyline points="14 2 14 8 20 8" />
                        <line x1={16} y1={13} x2={8} y2={13} />
                        <line x1={16} y1={17} x2={8} y2={17} />
                        <polyline points="10 9 9 9 8 9" />
                      </svg>
                      {data.lessons.length} lessons
                    </li>
                    {/* <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-user"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                      104 studnet
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*================= Breadcrumbs Section End Here =================*/}
        {/*================= Course Filter Section Start Here =================*/}
        <div className="back__course__page_grid react-courses__single-page pb---16 pt---110">
          <div className="container pb---70">
            <div className="row">
              <div className="col-lg-8">
                <div className="course-single-tab">
                  <ul className="nav nav-tabs" id="back-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="discriptions"
                        data-bs-toggle="tab"
                        href="#discription"
                        role="tab"
                        aria-controls="discription"
                        aria-selected="true"
                      >
                        Discription
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="curriculums"
                        data-bs-toggle="tab"
                        href="#curriculum"
                        role="tab"
                        aria-controls="curriculum"
                        aria-selected="false"
                      >
                        Curriculum
                      </a>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="reviewss"
                        data-bs-toggle="tab"
                        href="#reviews"
                        role="tab"
                        aria-controls="reviews"
                        aria-selected="false"
                      >
                        Reviews
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="members"
                        data-bs-toggle="tab"
                        href="#member"
                        role="tab"
                        aria-controls="member"
                        aria-selected="false"
                      >
                        Q&A
                      </a>
                    </li> */}
                  </ul>
                  <div className="tab-content" id="back-tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="discription"
                      role="tabpanel"
                      aria-labelledby="discription"
                    >
                      <h3>About Course</h3>

                      <p
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        {data.course.description.en}
                      </p>
                      <div className="image-banner">
                        <img
                          src={`${process.env.REACT_APP_VERSEL_API}/${data.course.image}`}
                          alt={data.course.teacher_id.name}
                        />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="curriculum"
                      role="tabpanel"
                      aria-labelledby="curriculum"
                    >
                      <h3>Curriculum</h3>
                      <div className="single-week">
                        <ul className="week__top">
                          <li>Lessons In This Course</li>
                          <li>{data.lessons.length}</li>
                        </ul>

                        {/* <h3>3 Videos,1 Audio,1 Reading</h3> */}
                        <ul className="course__title">
                          {data.lessons.map((item, index) => {
                            return (
                              <li>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-video"
                                >
                                  <polygon points="23 7 16 12 23 17 23 7" />
                                  <rect
                                    x={1}
                                    y={5}
                                    width={15}
                                    height={14}
                                    rx={2}
                                    ry={2}
                                  />
                                </svg>

                                <b> {item.name}</b>
                              </li>
                            );
                          })}
                          {/* <li>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-video"
                            >
                              <polygon points="23 7 16 12 23 17 23 7" />
                              <rect
                                x={1}
                                y={5}
                                width={15}
                                height={14}
                                rx={2}
                                ry={2}
                              />
                            </svg>
                            <b>Lessone one :</b> Intorduction To Space
                            <em className="questions">2 Question</em>
                            <em>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-clock"
                              >
                                <circle cx={12} cy={12} r={10} />
                                <polyline points="12 6 12 12 16 14" />
                              </svg>
                              12 min
                            </em>
                          </li>
                          <li>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-video"
                            >
                              <polygon points="23 7 16 12 23 17 23 7" />
                              <rect
                                x={1}
                                y={5}
                                width={15}
                                height={14}
                                rx={2}
                                ry={2}
                              />
                            </svg>
                            <b>Lesson Two:</b>Introduction To satalites{" "}
                            <em>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-clock"
                              >
                                <circle cx={12} cy={12} r={10} />
                                <polyline points="12 6 12 12 16 14" />
                              </svg>
                              26 min
                            </em>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    {/* <div
                      className="tab-pane fade"
                      id="reviews"
                      role="tabpanel"
                      aria-labelledby="reviews"
                    >
                      <h3>Reviews </h3>
                      <div className="row mt---40 mb----40">
                        <div className="col-lg-4">
                          <div className="five__number">
                            <em>4.5</em>
                            <div className="back-ratings">
                              <i className="icon_star" />
                              <i className="icon_star" />
                              <i className="icon_star" />
                              <i className="icon_star" />
                              <i className="icon_star" />
                            </div>
                            <h6>(4 review)</h6>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="back-progress-bar">
                            <div className="skillbar-style2">
                              <div className="skill_inners">
                                <span className="skillbar-title">5 stars</span>
                                <div className="skillbars green-dark-bg">
                                  <p className="skillbar-bar w-100" />
                                </div>
                                <span>2</span>
                              </div>
                              <div className="skill_inners">
                                <span className="skillbar-title">4 stars</span>
                                <div className="skillbars green-dark-bg">
                                  <p className="skillbar-bar w-75" />
                                </div>
                                <span>1</span>
                              </div>
                              <div className="skill_inners">
                                <span className="skillbar-title">3 stars</span>
                                <div className="skillbars green-dark-bg">
                                  <p className="skillbar-bar w-50" />
                                </div>
                                <span>1</span>
                              </div>
                              <div className="skill_inners">
                                <span className="skillbar-title">2 stars</span>
                                <div className="skillbars green-dark-bg">
                                  <p className="skillbar-bar w-25" />
                                </div>
                                <span>0</span>
                              </div>
                              <div className="skill_inners">
                                <span className="skillbar-title">1 star </span>
                                <div className="skillbars green-dark-bg">
                                  <p className="skillbar-bar" />
                                </div>
                                <span>0</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a href="#" className="post-author">
                        <div className="avatar">
                          <img
                            src={user4}
                            alt="user"
                          />
                        </div>
                        <div className="info">
                          <div className="back-ratings">
                            <i className="icon_star" />
                            <i className="icon_star" />
                            <i className="icon_star" />
                            <i className="icon_star" />
                            <i className="icon_star" />
                          </div>
                          <h4 className="name">
                            Hassan ali &nbsp;
                            <span className="designation">8 july ,2023</span>
                          </h4>
                          <p>
                            of letters, as opposed to using 'Content here,
                            content here', making it look like readable English.
                            Many desktop publishing packages and web page
                          </p>
                        </div>
                      </a>
                      <a href="#" className="post-author">
                        <div className="avatar">
                          <img
                            src={user5}
                            alt="user"
                          />
                        </div>
                        <div className="info">
                          <div className="back-ratings">
                            <i className="icon_star" />
                            <i className="icon_star" />
                            <i className="icon_star" />
                            <i className="icon_star" />
                            <i className="icon_star" />
                          </div>
                          <h4 className="name">
                            Manar Hassan &nbsp;
                            <span className="designation">8 july ,2023</span>
                          </h4>
                          <p>
                            of letters, as opposed to using 'Content here,
                            content here', making it look like readable English.
                            Many desktop publishing packages and web page
                          </p>
                        </div>
                      </a>

                      <div className="blog-form pt---30">
                        <h3>Leave Comment </h3>
                        <p className="pb---15">
                          Email will not gose public <br />
                          fild with * are required{" "}
                        </p>
                        <form id="contact-form">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="back-input">
                                <input
                                  id="name"
                                  type="text"
                                  name="name"
                                  placeholder="Name "
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 pdl-5">
                              <div className="back-input">
                                <input
                                  id="email"
                                  type="email"
                                  name="email"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="back-input">
                                <input
                                  id="subject"
                                  type="text"
                                  name="subject"
                                  placeholder="Subject"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="back-ratings">
                                <b>Review : </b>
                                <i className="icon_star" />
                                <i className="icon_star" />
                                <i className="icon_star" />
                                <i className="icon_star" />
                                <i className="icon_star" />
                              </div>
                              <div className="back-textarea">
                                <textarea
                                  id="message"
                                  name="message"
                                  placeholder="Message"
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <button
                                type="submit"
                                className="back-btn bgclr-red"
                              >
                                send{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-arrow-right"
                                >
                                  <line x1={5} y1={12} x2={19} y2={12} />
                                  <polyline points="12 5 19 12 12 19" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="member"
                      role="tabpanel"
                      aria-labelledby="member"
                    >
                      <h3>Questions And Asnwers </h3>
                      <p>
                        of letters, as opposed to using 'Content here, content
                        here', making it look like readable English. Many
                        desktop publishing packages and web pages
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 md-mt-60">
                <div className="react-sidebar react-back-course2 ml----30">
                  <div className="widget get-back-course">
                    <ul className="price">
                      <li className="clr-red">
                        Peice : {data.course.price / 100} EG
                      </li>
                    </ul>
                    <ul className="price__course">
                      <li>
                        <i className="icon_profile" />
                        Teacher
                        <b>{data.course.teacher_id.name}</b>
                      </li>
                      {/* <li>
                        <i className="icon_tag_alt" /> Subject
                        <b>Space</b>
                      </li>
                      <li>
                        <i className="icon_group" /> Regster
                        <b>146 student</b>
                      </li> */}
                      {/* <li>
                        <i className="icon_clock_alt" /> Duration <b>4 weeks</b>
                      </li> */}
                      <li>
                        <i className="icon_book_alt" /> Lessons
                        <b>{data.lessons.length} Lessons</b>
                      </li>
                      <li>
                        <i className="icon_map_alt" /> Language <b> Arabic</b>
                      </li>
                    </ul>
                    <Link
                      to={`/cart/${data.course._id}`}
                      className="start-btn bgclr-red"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-arrow-right"
                      >
                        <line x1={5} y1={12} x2={19} y2={12} />
                        <polyline points="12 5 19 12 12 19" />
                      </svg>
                      Buy Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*================= Course Filter Section End Here =================*/}
      </div>
    </>
  );
}
