import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import "./lessonstyle.css";
import Card from "react-bootstrap/Card";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";
import { getLessonsFromApi } from "../../../store/getAllLessonsSlicer";
import { getSingleLessonFromApi } from "../../../store/getSingleLessonsSlicer";
import Loader from "../../../component/Loader";
import ErrorStatment from "../../../component/ui/ErrorStatment";

//pdf img
import fileImg from '../../../Images/banner2/pdf.png'


export default function LessonsArea({ lessonId }) {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);
  const { loading, error } = useSelector((state) => state.getCoursesLessons);

  const { singleLoading, singleError } = useSelector(
    (state) => state.getSingleLessons
  );
  const dispatch = useDispatch();
  const [lessonsData, setLessonsData] = useState([]);
  const [singleLessonData, setSingleLessonData] = useState({});

  const getAllLessons = () => {
    dispatch(getLessonsFromApi(lessonId)).then((res) => {
      if (res.payload.data) {
        setLessonsData(res.payload.data);

        getSingleLesson(res.payload.data[0]._id);
      } else {
        setLessonsData([]);
      }
    });
  };

  const getSingleLesson = (singlelessonID) => {
    dispatch(getSingleLessonFromApi(singlelessonID)).then((res) => {
      if (res.payload.data) {
        setSingleLessonData(res.payload.data);
      } else {
        setSingleLessonData({});
      }
    });
  };

  function LessonsAreaArabic() {
    return (
      <>
        {loading ? (
          <div className="react-course-filter back__course__page_grid pt---140 pt---110" style={{
            minHeight: "100vh",
          }}>
            <div className="container pb---70">
              <div className="row">
                <div className="col-12 text-center">
                  <Loader />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {error ? (
              <>
                <ErrorStatment />
              </>
            ) : (
              <>
                <div className="about__area about__area_one lessons__study__area pt---100 ">
                  <div className="row">
                    <div
                      className="col-lg-4 col-md-12 col-sm-12"
                      id="lessonSide"
                    >
                      <Accordion defaultActiveKey={["0"]} alwaysOpen>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>المقرر في الكورس</Accordion.Header>
                          <Accordion.Body>
                            <ul className="weeks course__title ">
                              {lessonsData.map((lesson, index) => {
                                return (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      getSingleLesson(lesson._id);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-video"
                                    >
                                      <polygon points="23 7 16 12 23 17 23 7" />
                                      <rect
                                        x={1}
                                        y={5}
                                        width={15}
                                        height={14}
                                        rx={2}
                                        ry={2}
                                      />
                                    </svg>
                                    <b> {lesson.name}</b>
                                    {/* <em>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-clock"
                          >
                            <circle cx={12} cy={12} r={10} />
                            <polyline points="12 6 12 12 16 14" />
                          </svg>
                          12 دقيقة
                        </em> */}
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    <div
                      className="col-lg-8 col-md-12 col-sm-12  pt---50"
                      id="videoSide"
                    >
                      {singleLoading ? (
                        <>
                          <div className="container pb---70">
                            <div className="row">
                              <div className="col-12 text-center">
                                <Loader />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {singleError ? (
                            <>
                              <ErrorStatment />
                            </>
                          ) : (
                            <>
                              {singleLessonData != {} && (
                                <div className="container">
                                  <div className="col-12 lesson-video">
                                    <h4 className="video-title">
                                      {singleLessonData.name}
                                    </h4>
                                    <div className="videos" style={{width:'100%'}}>
                                      <ReactPlayer
                                      className="react-player" style={{
                                        margin:'auto',
                                      }}
                                        // playing={isplaying}
                                        // ref={videroPlayerRef}
                                        config={{
                                          file: {
                                            attributes: {
                                              controlsList: "nodownload",
                                            },
                                          },
                                        }}
                                       
                                        url={`${process.env.REACT_APP_VERSEL_API}/${singleLessonData.video}`}
                                        controls
                                      />
                                    </div>
                                    {singleLessonData.file &&<>
                                      <div className="files py-4">
                                      <a target="_blank" href={`${process.env.REACT_APP_VERSEL_API}/${singleLessonData.file.path}`} >
                                        <img src={fileImg} title="file icon" width={50}/>
                                        <p>{singleLessonData.file.name}</p>
                                      </a>
                                    </div>
                                    </>}
                                   
                                    {/* <div className="contro-buttons d-flex gap-3 m-1 p-4">
                                    <button className="btn btn-danger">
                                      التالي{" "}
                                    </button>
                                    <button className="btn btn-danger">
                                      السابق{" "}
                                    </button>
                                  </div> */}
                                  </div>
                                  <>
                                    {/* <div className="col-12 lesson-quize">
                  <div class="quiz">
                    <Card className="card quiz-question col-lg-8 col-sm-12 p-2 my-3">
                      <Card.Title className="d-flex justify-content-between">
                        <span>السوال الاول</span>
                        <span>
                          <em>الدرجة</em>: 1
                        </span>
                      </Card.Title>
                      <hr />
                      <Card.Title>
                        <h3>ما هو الرقم الصحيح للجازبية علي القمر ؟</h3>
                      </Card.Title>
                      <Card.Body >
                        <div className="d-flex gap-2 align-items-center py-1">
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                           id="answer-1"
                           name="q1"
                          />
                          <Form.Check.Label htmlFor="answer-1">الاجابة الاولي </Form.Check.Label>
                        </div>
                        <div className="d-flex gap-2 align-items-center py-1">
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                           id="answer-2"
                           name="q1"
                          />
                          <Form.Check.Label htmlFor="answer-2">الاجابة الثامية  </Form.Check.Label>
                        </div>
                        <div className="d-flex gap-2 align-items-center py-1">
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                           id="answer-3"
                           name="q1"
                          />
                          <Form.Check.Label htmlFor="answer-3">الاجابة الثالثة </Form.Check.Label>
                        </div>
                        <div className="d-flex gap-2 align-items-center py-1">
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                           id="answer-4"
                           name="q1"
                          />
                          <Form.Check.Label htmlFor="answer-4">الاجابة الرابعة  </Form.Check.Label>
                        </div>
                      </Card.Body>
                    </Card>
                    <Card className="card quiz-question col-lg-8 col-sm-12 p-2 my-3">
                      <Card.Title className="d-flex justify-content-between">
                        <span>السوال الثاني</span>
                        <span>
                          <em>الدرجة</em>: 1
                        </span>
                      </Card.Title>
                      <hr />
                      <Card.Title>
                        <h3>هل جازبية القمر اكبر من جازبية المريخ  ؟</h3>
                      </Card.Title>
                      <Card.Body >
                        <div className="d-flex gap-2 align-items-center py-1">
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                           id="q2-answer-1"
                           name="q2"
                          />
                          <Form.Check.Label htmlFor="q2-answer-1">اجابة صحيحة </Form.Check.Label>
                        </div>
                        <div className="d-flex gap-2 align-items-center py-1">
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                           id="q2-answer-2"
                           name="q2"
                          />
                          <Form.Check.Label htmlFor="q2-answer-2">اجابة خاطئة   </Form.Check.Label>
                        </div>
                        
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="contro-buttons d-flex gap-3 m-1 p-4">
                    <button className="btn btn-lg btn-danger ">انهاء </button>
                  </div>
                </div> */}
                                  </>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
  function LessonsAreaEnglish() {
    return (
      <>
        {loading ? (
          <div className="react-course-filter back__course__page_grid pt---140 pt---110" style={{
            minHeight: "100vh",
          }}>
            <div className="container pb---70">
              <div className="row">
                <div className="col-12 text-center">
                  <Loader />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {error ? (
              <>
                <ErrorStatment />
              </>
            ) : (
              <>
                <div className="about__area about__area_one lessons__study__area pt---100 ">
                  <div className="row">
                    <div
                      className="col-lg-4 col-md-12 col-sm-12"
                      id="lessonSide"
                    >
                      <Accordion defaultActiveKey={["0"]} alwaysOpen>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            Lessons in this course
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul className="weeks course__title ">
                              {lessonsData.map((lesson, index) => {
                                return (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      getSingleLesson(lesson._id);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-video"
                                    >
                                      <polygon points="23 7 16 12 23 17 23 7" />
                                      <rect
                                        x={1}
                                        y={5}
                                        width={15}
                                        height={14}
                                        rx={2}
                                        ry={2}
                                      />
                                    </svg>
                                    <b> {lesson.name}</b>
                                    {/* <em>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-clock"
                          >
                            <circle cx={12} cy={12} r={10} />
                            <polyline points="12 6 12 12 16 14" />
                          </svg>
                          12 دقيقة
                        </em> */}
                                  </li>
                                );
                              })}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    <div
                      className="col-lg-8 col-md-12 col-sm-12  pt---50"
                      id="videoSide"
                    >
                      {singleLoading ? (
                        <>
                          <div className="container pb---70">
                            <div className="row">
                              <div className="col-12 text-center">
                                <Loader />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {singleError ? (
                            <>
                              <ErrorStatment />
                            </>
                          ) : (
                            <>
                              {singleLessonData != {} && (
                                <div className="container">
                                  <div className="col-12 lesson-video">
                                    <h4 className="video-title">
                                      {singleLessonData.name}
                                    </h4>
                                    <div className="videos" style={{width:'100%'}}>
                                      <ReactPlayer
                                      className="react-player" style={{
                                        margin:'auto',
                                      }}
                                        // playing={isplaying}
                                        // ref={videroPlayerRef}
                                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                      
                                        url={`${process.env.REACT_APP_VERSEL_API}/${singleLessonData.video}`}
                                        controls
                                      />
                                    </div>
                                    {singleLessonData.file &&<>
                                      <div className="files py-4">
                                      <a target="_blank" href={`${process.env.REACT_APP_VERSEL_API}/${singleLessonData.file.path}`} >
                                        <img src={fileImg} title="file icon" width={50}/>
                                        <p>{singleLessonData.file.name}</p>
                                      </a>
                                    </div>
                                    </>}
                                    {/* <div className="contro-buttons d-flex gap-3 m-1 p-4">
                                    <button className="btn btn-danger">
                                      التالي{" "}
                                    </button>
                                    <button className="btn btn-danger">
                                      السابق{" "}
                                    </button>
                                  </div> */}
                                  </div>
                                  <>
                                    {/* <div className="col-12 lesson-quize">
                  <div class="quiz">
                    <Card className="card quiz-question col-lg-8 col-sm-12 p-2 my-3">
                      <Card.Title className="d-flex justify-content-between">
                        <span>السوال الاول</span>
                        <span>
                          <em>الدرجة</em>: 1
                        </span>
                      </Card.Title>
                      <hr />
                      <Card.Title>
                        <h3>ما هو الرقم الصحيح للجازبية علي القمر ؟</h3>
                      </Card.Title>
                      <Card.Body >
                        <div className="d-flex gap-2 align-items-center py-1">
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                           id="answer-1"
                           name="q1"
                          />
                          <Form.Check.Label htmlFor="answer-1">الاجابة الاولي </Form.Check.Label>
                        </div>
                        <div className="d-flex gap-2 align-items-center py-1">
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                           id="answer-2"
                           name="q1"
                          />
                          <Form.Check.Label htmlFor="answer-2">الاجابة الثامية  </Form.Check.Label>
                        </div>
                        <div className="d-flex gap-2 align-items-center py-1">
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                           id="answer-3"
                           name="q1"
                          />
                          <Form.Check.Label htmlFor="answer-3">الاجابة الثالثة </Form.Check.Label>
                        </div>
                        <div className="d-flex gap-2 align-items-center py-1">
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                           id="answer-4"
                           name="q1"
                          />
                          <Form.Check.Label htmlFor="answer-4">الاجابة الرابعة  </Form.Check.Label>
                        </div>
                      </Card.Body>
                    </Card>
                    <Card className="card quiz-question col-lg-8 col-sm-12 p-2 my-3">
                      <Card.Title className="d-flex justify-content-between">
                        <span>السوال الثاني</span>
                        <span>
                          <em>الدرجة</em>: 1
                        </span>
                      </Card.Title>
                      <hr />
                      <Card.Title>
                        <h3>هل جازبية القمر اكبر من جازبية المريخ  ؟</h3>
                      </Card.Title>
                      <Card.Body >
                        <div className="d-flex gap-2 align-items-center py-1">
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                           id="q2-answer-1"
                           name="q2"
                          />
                          <Form.Check.Label htmlFor="q2-answer-1">اجابة صحيحة </Form.Check.Label>
                        </div>
                        <div className="d-flex gap-2 align-items-center py-1">
                          <Form.Check
                            type="radio"
                            aria-label="radio 1"
                           id="q2-answer-2"
                           name="q2"
                          />
                          <Form.Check.Label htmlFor="q2-answer-2">اجابة خاطئة   </Form.Check.Label>
                        </div>
                        
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="contro-buttons d-flex gap-3 m-1 p-4">
                    <button className="btn btn-lg btn-danger ">انهاء </button>
                  </div>
                </div> */}
                                  </>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }

  // function LessonsAreaEnglish() {
  //   return (
  //     <>
  //       <div className="about__area about__area_one lessons__study__area pt---100 ">
  //         <div className="row">
  //           <div className="col-lg-4 col-md-12 col-sm-12" id="lessonSide">
  //             <Accordion defaultActiveKey={["0"]} alwaysOpen>
  //               <Accordion.Item eventKey="0">
  //                 <Accordion.Header>Week 1</Accordion.Header>
  //                 <Accordion.Body>
  //                   <ul className="weeks course__title ">
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         width={24}
  //                         height={24}
  //                         viewBox="0 0 24 24"
  //                         fill="none"
  //                         stroke="currentColor"
  //                         strokeWidth={2}
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         className="feather feather-video"
  //                       >
  //                         <polygon points="23 7 16 12 23 17 23 7" />
  //                         <rect
  //                           x={1}
  //                           y={5}
  //                           width={15}
  //                           height={14}
  //                           rx={2}
  //                           ry={2}
  //                         />
  //                       </svg>
  //                       <b> Lesson one : </b> Introduction to Satelites
  //                       <em>
  //                         <svg
  //                           xmlns="http://www.w3.org/2000/svg"
  //                           width={24}
  //                           height={24}
  //                           viewBox="0 0 24 24"
  //                           fill="none"
  //                           stroke="currentColor"
  //                           strokeWidth={2}
  //                           strokeLinecap="round"
  //                           strokeLinejoin="round"
  //                           className="feather feather-clock"
  //                         >
  //                           <circle cx={12} cy={12} r={10} />
  //                           <polyline points="12 6 12 12 16 14" />
  //                         </svg>
  //                         12 min
  //                       </em>
  //                     </li>
  //                     <li>
  //                       <svg
  //                         xmlns="http://www.w3.org/2000/svg"
  //                         height="1.5em"
  //                         viewBox="0 0 384 512"
  //                       >
  //                         <path d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM105.8 229.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L216 328.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V314.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H158.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM160 416a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
  //                       </svg>
  //                       <b>Quize :</b> frist lesson
  //                       <em className="questions">20 questions</em>
  //                     </li>
  //                   </ul>
  //                 </Accordion.Body>
  //               </Accordion.Item>
  //             </Accordion>
  //           </div>
  //           <div
  //             className="col-lg-8 col-md-12 col-sm-12  pt---50"
  //             id="videoSide"
  //           >
  //             <div className="container">
  //               <div className="col-12 lesson-video">
  //                 <h4 className="video-title">
  //                   lesson one : intro to something
  //                 </h4>
  //                 <div class="videos">
  //                   {/* <iframe
  //                     width="100%"
  //                     height="400"
  //                     src="https://www.youtube.com/embed/e5Hc2B50Z7c"
  //                     title="YouTube video player"
  //                     frameborder="0"
  //                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //                     allowfullscreen=""
  //                   ></iframe> */}
  //                   <ReactPlayer
  //                     // playing={isplaying}
  //                     // ref={videroPlayerRef}
  //                     width={"100%"}
  //                     url={
  //                       "https://firebasestorage.googleapis.com/v0/b/wedfirebase.appspot.com/o/ocean-62249.mp4?alt=media&token=27a8ec3c-da83-463f-8367-895a6f814b42"
  //                     }
  //                     controls
  //                   />
  //                 </div>
  //                 <div className="contro-buttons d-flex gap-3 m-1 p-4">
  //                   <button className="btn btn-danger">Next </button>
  //                   <button className="btn btn-danger">prive </button>
  //                 </div>
  //               </div>
  //               {/* <div className="col-12 lesson-quize">
  //                 <div class="quiz">
  //                   <Card className="card quiz-question col-lg-8 col-sm-12 p-2 my-3">
  //                     <Card.Title className="d-flex justify-content-between">
  //                       <span>Question one</span>
  //                       <span>
  //                         <em>Degree</em>: 1
  //                       </span>
  //                     </Card.Title>
  //                     <hr />
  //                     <Card.Title>
  //                       <h3>This Is Fris Question ?</h3>
  //                     </Card.Title>
  //                     <Card.Body >
  //                       <div className="d-flex gap-2 align-items-center py-1">
  //                         <Form.Check
  //                           type="radio"
  //                           aria-label="radio 1"
  //                          id="answer-1"
  //                          name="q1"
  //                         />
  //                         <Form.Check.Label htmlFor="answer-1">Answer a </Form.Check.Label>
  //                       </div>
  //                       <div className="d-flex gap-2 align-items-center py-1">
  //                         <Form.Check
  //                           type="radio"
  //                           aria-label="radio 1"
  //                          id="answer-2"
  //                          name="q1"
  //                         />
  //                         <Form.Check.Label htmlFor="answer-2">Answer b  </Form.Check.Label>
  //                       </div>
  //                       <div className="d-flex gap-2 align-items-center py-1">
  //                         <Form.Check
  //                           type="radio"
  //                           aria-label="radio 1"
  //                          id="answer-3"
  //                          name="q1"
  //                         />
  //                         <Form.Check.Label htmlFor="answer-3">Answer c </Form.Check.Label>
  //                       </div>
  //                       <div className="d-flex gap-2 align-items-center py-1">
  //                         <Form.Check
  //                           type="radio"
  //                           aria-label="radio 1"
  //                          id="answer-4"
  //                          name="q1"
  //                         />
  //                         <Form.Check.Label htmlFor="answer-4">Answer d  </Form.Check.Label>
  //                       </div>
  //                     </Card.Body>
  //                   </Card>
  //                   <Card className="card quiz-question col-lg-8 col-sm-12 p-2 my-3">
  //                     <Card.Title className="d-flex justify-content-between">
  //                       <span>Question Two </span>
  //                       <span>
  //                         <em>Degree</em>: 1
  //                       </span>
  //                     </Card.Title>
  //                     <hr />
  //                     <Card.Title>
  //                       <h3>This is true and false question ?</h3>
  //                     </Card.Title>
  //                     <Card.Body >
  //                       <div className="d-flex gap-2 align-items-center py-1">
  //                         <Form.Check
  //                           type="radio"
  //                           aria-label="radio 1"
  //                          id="q2-answer-1"
  //                          name="q2"
  //                         />
  //                         <Form.Check.Label htmlFor="q2-answer-1">True </Form.Check.Label>
  //                       </div>
  //                       <div className="d-flex gap-2 align-items-center py-1">
  //                         <Form.Check
  //                           type="radio"
  //                           aria-label="radio 1"
  //                          id="q2-answer-2"
  //                          name="q2"
  //                         />
  //                         <Form.Check.Label htmlFor="q2-answer-2">False   </Form.Check.Label>
  //                       </div>

  //                     </Card.Body>
  //                   </Card>
  //                 </div>
  //                 <div className="contro-buttons d-flex gap-3 m-1 p-4">
  //                   <button className="btn btn-lg btn-danger ">submite </button>
  //                 </div>
  //               </div> */}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // }

  useEffect(() => {
    getAllLessons();
  }, []);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);

  return <>{isArlanguage ? <LessonsAreaArabic /> : <LessonsAreaEnglish />}</>;
}
