import { Link } from "react-router-dom";
import BTN from "../../../component/ui/BTN";
import Btn from "../../../component/sub-components/Btn";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// image
import featured_01 from "../../../Images/course-filter/orbital1.png";

import { getCourse } from "../../../store/getCourseByIdSlicer";
import Loader from "../../../component/Loader";
import IMG from "../../../component/ui/IMG";

export default function UserCart({ data }) {
  const [courseData, setCourseData] = useState(data);
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);

  return (
    <>
      {isArlanguage ? (
        <UserCartArabic data={courseData} />
      ) : (
        <UserCartEnglish data={courseData} />
      )}
    </>
  );
}

function UserCartArabic({ data }) {
  return (
    <>
      <div className="cart__user__area pt---140 pb---90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="text-right clr-red"> العربة الخاصة</h3>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="price-info">
                <h5>المبلغ الكلي :</h5>
                <h3> {data.course.price /100} جنيها </h3>
                <div className="py-2 text-center">
                  <Link
                    to={`/payment/${data.course._id}`}
                    className="btn btn-danger col-12 p-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-arrow-right"
                    >
                      <line x1={5} y1={12} x2={19} y2={12} />
                      <polyline points="12 5 19 12 12 19" />
                    </svg>
                    شراء الان
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              <div className=" mb-3">
                <div className="row g-0">
                  <div className="col-3 ">
                  {<IMG src={data.course.image} title={data.course.name.en}/>}
                    
                  </div>
                  <div className="col-7">
                    <div className="card-body">
                      <h5 className="card-title">{data.course.name.ar}</h5>
                      <p className="card-text" style={{
                          width:'100%',
                          height:"50px",
                          textOverflow: 'ellipsis',
                          fontSize: '10px',
                          overflow:'hidden'

                        }}>{data.course.description.ar}</p>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="card-body">
                      <h5 className="card-title">{data.course.price /100} جنيها </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function UserCartEnglish({ data }) {
  return (
    <>
      <div className="cart__user__area pt---140 pb---90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="text-left clr-red"> Shopping Cart</h3>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="price-info">
                <h5>Total :</h5>
                <h3> {data.course.price/100} $ </h3>
                <div className="py-2 text-center">
                  <Link
                    to={`/payment/${data.course._id}`}
                    className="btn btn-danger col-12 p-3"
                  >
                    Checkout
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-arrow-right"
                    >
                      <line x1={5} y1={12} x2={19} y2={12} />
                      <polyline points="12 5 19 12 12 19" />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              <div className=" mb-3">
                <div className="row g-0">
                  <div className="col-3">
                  {<IMG src={data.course.image} title={data.course.name.en}/>}
                    
                  </div>
                  <div className="col-7">
                    <div className="card-body">
                      <h5 className="card-title">{data.course.name.en}</h5>
                      <p className="card-text" style={{
                          width:'100%',
                          height:"50px",
                          textOverflow: 'ellipsis',
                          fontSize: '10px',
                          overflow:'hidden'

                        }}>{data.course.description.en}</p>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="card-body">
                      <h5 className="card-text">{data.course.price/100} $ </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
