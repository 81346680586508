import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function ErrorTokenExpired() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);
  return <>{isArlanguage ? <ErrorTokenExpiredArabic /> : <ErrorTokenExpiredEnglish />}</>;
}

function ErrorTokenExpiredArabic() {
  return<>
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center ">
          <div className="col-md-5">
            <div className="form-input-content text-center error-page">
              {/* <h1 className="error-text font-weight-bold">404</h1> */}
              <h3>
                <i className="fa fa-exclamation-triangle text-warning" />&nbsp;
                 انتهاء الصلاحية 
              </h3>
              <p > انتهت صلاحية الرابط المرسل يرجي اعادة المحاولة 
</p>
              <div>
                <Link className="btn btn-danger" to="/forget-password">
                            اعادة تعيين كلمة المرور
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
}
function ErrorTokenExpiredEnglish() {
    return<>
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center ">
          <div className="col-md-5">
            <div className="form-input-content text-center error-page">
              {/* <h1 className="error-text font-weight-bold">404</h1> */}
              <h3>
                <i className="fa fa-exclamation-triangle text-warning" /> 
                &nbsp; Expired Link
              </h3>
              <p > The Link is Expired, Please Try Again
</p>
              <div>
                <Link className="btn btn-danger" to="/forget-password">
               reset Password
                                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
}
