import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../../store/userDataSlicer";
import { LogedIn } from "../../../store/AuthIsLoginSlicer";
import Loader from "../../../component/Loader";

export default function LoginForm() {
  // translation mode
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);

  
  function LoginFormArabic() {
    // Stroe Data
    const { loading, user, error } = useSelector((state) => state.userData);
    const dispatch = useDispatch();
    const navigator = useNavigate();

    //hassan@test.com 12345678
    // handle from data
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userEmailError, setUserEmailError] = useState("");
    const [userPasswordError, setUserPasswordError] = useState("");

    const handleSubmite = (e) => {
      e.preventDefault();
      if (userEmailError === "" && userPasswordError === "") {
        const userCredintianals = {
          email: userEmail,
          password: userPassword,
        };
        dispatch(loginUser(userCredintianals)).then((result) => {
          if (result.payload.message === "loggedIn") {
            dispatch(LogedIn())
            setUserEmail("");
            setUserPassword("");
            navigator("/");
          }
        });
      }
    };

    return (
      <>
        <div className="react-login-page react-signup-page pt---150 pb---120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="login-right-form">
                  <form onSubmit={handleSubmite}>
                    <div className="login-top">
                      <div className="logo">
                        <h2>تسجيل الدخول</h2>
                      </div>
                      <p>
                        ليس لديك حساب ؟<Link to={"/singup"}> انشاء حسابا </Link>
                      </p>
                    </div>
                    <p>
                      <label>البريد الالكتروني</label>
                      <input
                        placeholder="البريد الالكتروني او اسم المستخدم"
                        type="email"
                        name="email"
                        required
                        onChange={(e) => {
                          if (e.target.value.length <= 10) {
                            setUserEmailError(" بريد الكتروني غير صالح");
                          } else {
                            setUserEmailError("");
                          }
                          setUserEmail(e.target.value);
                        }}
                        value={userEmail}
                      />
                    </p>
                    {userEmailError && (
                      <Alert variant="danger" className="mt-2">
                        {userEmailError}
                      </Alert>
                    )}
                    <p>
                      <label>كلمة المرور</label>
                      <input
                        placeholder="كلمة المرور"
                        type="password"
                        name="password"
                        required
                        onChange={(e) => {
                          if (e.target.value.length < 8) {
                            setUserPasswordError(
                              "كلمة المرور يجب ان تكون اكبر من 8 احرف"
                            );
                          } else {
                            setUserPasswordError("");
                          }

                          setUserPassword(e.target.value);
                        }}
                        value={userPassword}
                      />
                    </p>
                    {userPasswordError && (
                      <Alert variant="danger" className="mt-2">
                        {userPasswordError}
                      </Alert>
                    )}
                    {/* <div className="back-check-box">
                      <input type="checkbox" id="box-1" />

                      <p>تذكرني</p>
                    </div> */}
                    {error && (
                      <div className="col">
                        <div className="alert alert-danger" role="alert">
                          {error}
                        </div>
                      </div>
                    )}
                    {loading ? (
                      <>
                        <button
                          type="submit"
                          id="button"
                          name="submit"
                          disabled
                        >
                          <Loader />
                          </button>
                      </>
                    ) : (
                      <>
                        <button type="submit" id="button" name="submit">
                          دخول
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right"
                          >
                            <line x1={5} y1={12} x2={19} y2={12} />
                            <polyline points="12 5 19 12 12 19" />
                          </svg>
                        </button>
                      </>
                    )}

                    <span className="back-or">
                      <Link to={"/forget-password"}>نسيت كلمة المرور ؟</Link>
                    </span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  function LoginFormEnglish() {
    // Stroe Data
    const { loading, user, error } = useSelector((state) => state.userData);
    const dispatch = useDispatch();
    const navegator = useNavigate();

    // handle from data
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userEmailError, setUserEmailError] = useState("");
    const [userPasswordError, setUserPasswordError] = useState("");

    const handleSubmite = (e) => {
      e.preventDefault();
      if (userEmailError === "" && userPasswordError === "") {
        const userCredintianals = {
          email: userEmail,
          password: userPassword,
        };
        dispatch(loginUser(userCredintianals)).then((result) => {
          if (result.payload.message === "loggedIn") {
            dispatch(LogedIn())
            setUserEmail("");
            setUserPassword("");
            navegator("/");
          }
        });
      }
    };
    return (
      <>
        <div className="react-login-page react-signup-page pt---150 pb---120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="login-right-form">
                  <form onSubmit={handleSubmite}>
                    <div className="login-top">
                      <div className="logo">
                        <h2>Login</h2>
                      </div>
                      <p>
                        don't have account ?
                        <Link to={"/singup"}>Create Account </Link>
                      </p>
                    </div>
                    <p>
                      <label>Email</label>
                      <input
                        placeholder="Email Or User Name"
                        type="email"
                        name="email"
                        required
                        onChange={(e) => {
                          if (e.target.value.length <= 10) {
                            setUserEmailError("Invaild Email ");
                          } else {
                            setUserEmailError("");
                          }
                          setUserEmail(e.target.value);
                        }}
                        value={userEmail}
                      />
                    </p>
                      {userEmailError && (
                        <Alert variant="danger" className="mt-2">
                          {userEmailError}
                        </Alert>
                      )}
                    <p>
                      <label>Password</label>
                      <input
                        placeholder="Password"
                        type="password"
                        name="password"
                        required
                        onChange={(e) => {
                          if (e.target.value.length < 8) {
                            setUserPasswordError(
                              "Password must be more then 8 character"
                            );
                          } else {
                            setUserPasswordError("");
                          }

                          setUserPassword(e.target.value);
                        }}
                        value={userPassword}
                      />
                    </p>
                      {userPasswordError && (
                        <Alert variant="danger" className="mt-2">
                          {userPasswordError}
                        </Alert>
                      )}
                    {/* <div className="back-check-box">
                      <input type="checkbox" id="box-1" />

                      <p>Remember me</p>
                    </div> */}
                    {error && (
                      <div className="col">
                        <div className="alert alert-danger" role="alert">
                          {error}
                        </div>
                      </div>
                    )}

                      {loading ? (
                    <button type="submit" id="button" name="submit" disabled >
                    <Loader />
                        </button>

                      ) : (
                        <>
                    <button type="submit" id="button" name="submit">

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right"
                          >
                            <line x1={5} y1={12} x2={19} y2={12} />
                            <polyline points="12 5 19 12 12 19" />
                          </svg>
                          Login
                    </button>

                        </>
                      )}
                    <span className="back-or">
                      <Link to={"/forget-password"}>Forget Password ?</Link>
                    </span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return <>{lang === "ar" ? <LoginFormArabic /> : <LoginFormEnglish />}</>;
}
