import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const loginUser = createAsyncThunk(
  "loginuser",

  async (userCredintianals) => {
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/login`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: userCredintianals.email,
          password: userCredintianals.password,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.message === 'loggedIn') {
        localStorage.setItem("userData", JSON.stringify(result));
        }        
        return result;
      })
      .catch((error) => error);
    const response = request;
    return response;
  }
);

const userDataSlicer = createSlice({
  name: "userData",
  initialState: {
    loading: false,
    user: null,
    error: null,
  },

  reducers: {
    Logout(state) {
      state.loading = false;
      state.user = null;
      state.error = null;
      localStorage.removeItem("userData");
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      if (action.payload.message === "loggedIn") {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      } else {
        state.loading = false;
        state.user = null;
        state.error = action.payload.message;
      }
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload.message;
    });
  },
});

export default userDataSlicer.reducer;
export const { Logout } = userDataSlicer.actions;
