import { useEffect, useState } from "react";
import avatar from "../Images/profile/2.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Logout } from "../store/userDataSlicer";
import { LogedOut } from "../store/AuthIsLoginSlicer";

export default function Avatar() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);
  const { them } = useSelector((state) => state.theam);
  const { user } = useSelector((state) => state.userData);
  const navegator  = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, []);

const handleLogout = ()=>{
    dispatch(Logout());
    navegator('/');
    window.location.reload()
}


  function AvararArabic({handleExpand}) {
    return (
      <>
      
        <ul className="custom-avatar-menu">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle d-flex align-items-center gap-1"
              href="#"
              role="button"
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={avatar}
                className="user-img rounded-circle"
                alt="Avatar"
              />
            </a>
            <ul
            className={`dropdown-menu dropdown-menu-${them}`}
              aria-labelledby="dropdownMenuButton2"
            >
              
              <li>
                <Link className="dropdown-item" to={"/profile"} onClick={()=>{
                  return handleExpand();
                }}>
                  الملف الشخصي
                </Link>
              </li>
              <li>
                <button  className="dropdown-item"  onClick={handleLogout} >
                  تسجيل خروج
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </>
    );
  }
  function AvararEnglish({handleExpand}) {
    return (
      <>
        <ul className=" custom-avatar-menu">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle d-flex align-items-center gap-1"
              href="#"
              role="button"
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={avatar}
                className="user-img rounded-circle"
                alt="Avatar"
              />
            </a>
            <ul
            className={`dropdown-menu dropdown-menu-${them}`}
              aria-labelledby="dropdownMenuButton2"
            >
              
              <li>
                <Link className="dropdown-item" to={"/profile"} onClick={()=>{
                  return handleExpand();
                }}>
                  Profile
                </Link>
              </li>
              <li>
                <button className="dropdown-item"  onClick={handleLogout}>
                  logout
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </>
    );
  }
  return <>
      {isArlanguage ? <AvararArabic /> : <AvararEnglish />}
  </>;
}
