import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function MinFooter() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);
  return <>{lang === "ar" ? <FooterArabic /> : <FooterEnglish />}</>;
}

function FooterArabic() {
  return (
    <>
      <footer id="react-footer" className="react-footer home-main">
      
      <div className="copyright">
            <div className="container">
              <div className="react-copy-left">
                © 2023 <a href="#">SPACECAVEZ</a> كل الحقوق محفوظة لدي شركتنا
              </div>
              <div className="react-copy-right">
                <ul className="social-links">
                  <li className="follow">تابعنا علي</li>
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=100090626162515"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/spacecavez/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/spacecavez1/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </footer>
    </>
  );
}
function FooterEnglish() {
  return (
    <>
      <footer id="react-footer" className="react-footer home-main">
       
      <div className="copyright">
            <div className="container">
              <div className="react-copy-left">
                © 2023 <a href="#">SPACECAVEZ.</a> All Rights Reserved
              </div>
              <div className="react-copy-right">
                <ul className="social-links">
                  <li className="follow">Follow Us</li>
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=100090626162515"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/spacecavez/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/spacecavez1/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </footer>
    </>
  );
}
