import { useEffect, useState } from "react";
import Btn from "../../../component/sub-components/Btn";
import { useSelector } from "react-redux";
import {
  ab,
  ab3,
  about2,
  about22,
  badge,
  comment,
  dot,
  dots1,
  dots2,
  shape_01,
  shape_02,
  shape_03,
  shape_044,
  signature,
} from "../../../Images/about/index";
import aboutimg from "../../../Images/about/ABOUT.png";

export default function PolicyArea() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);
  return <>{lang === "ar" ? <AboutAreaArabic /> : <AboutAreaEnglish />}</>;
}

function AboutAreaArabic() {
  return (
    <>
      <div className="about__area about__area_one p-relative pt---100  pb---120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="about__content">
                <h2 className="about__title">سياسة الاسترجاع:</h2>
                <p>تم التعديل في : اكتوبر , 2023</p>
                <hr />
                <br />

                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  في spacecavez، نسعى جاهدين لتوفير أفضل تجربة تعليمية
                  لمستخدمينا. ومع ذلك، فإننا ندرك أنه قد تكون هناك حالات يكون
                  فيها استرداد الأموال ضروريًا.
                </p>
                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  سيتم النظر في طلبات استرداد الأموال على أساس كل حالة على حدة
                  ويجب تقديمها خلال 30 يومًا من تاريخ الشراء الأصلي. لطلب
                  استرداد الأموال، يرجى الاتصال بفريق دعم العملاء لدينا على
                  <a href="mailto:info@spacecavez.com">
                    info@spacecavez.com
                  </a>{" "}
                  مع تفاصيل طلبك وسبب طلب استرداد الأموال.
                </p>
                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  يرجى ملاحظة أنه لن يتم توفير المبالغ المستردة للمنتجات الرقمية
                  التي تم الوصول إليها أو تنزيلها، ما لم تكن هناك مشكلة فنية
                  تمنع الوصول إلى المحتوى.
                </p>
                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  ونحن ملتزمون بحل أي مشاكل وضمان رضا العملاء. إذا كانت لديك أي
                  مخاوف بشأن عملية الشراء، فيرجى الاتصال بنا وسنبذل قصارى جهدنا
                  لمساعدتك.
                </p>

                <h2 className="about__title">سياسة الخصوصية :</h2>

                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  في spacecavez، نحن نأخذ خصوصيتك وأمن بياناتك على محمل الجد.
                  نحن ملتزمون بحماية المعلومات الشخصية لمستخدمينا والحفاظ على
                  الشفافية في كيفية جمع البيانات واستخدامها وتخزينها.
                </p>

                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  عندما تستخدم منصتنا، قد نقوم بجمع معلومات شخصية معينة مثل اسمك
                  وعنوان بريدك الإلكتروني وتفاصيل الدفع. يتم استخدام هذه
                  المعلومات فقط لغرض تزويدك بإمكانية الوصول إلى المحتوى التعليمي
                  الخاص بنا ومعالجة المعاملات.
                </p>

                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  نحن لا نبيع أو نشارك معلوماتك الشخصية مع أطراف ثالثة دون
                  موافقتك. نحن نستخدم إجراءات أمنية متوافقة مع معايير الصناعة
                  لحماية بياناتك من الوصول أو الكشف غير المصرح به.
                </p>
                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  قد يستخدم نظامنا الأساسي ملفات تعريف الارتباط والتقنيات
                  المشابهة لتحسين تجربة المستخدم الخاصة بك وتتبع أنماط
                  الاستخدام. لديك خيار إدارة تفضيلات ملفات تعريف الارتباط من
                  خلال إعدادات المتصفح الخاص بك.
                </p>
                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  باستخدام منصتنا، فإنك توافق على جمع واستخدام معلوماتك الشخصية
                  على النحو المبين في سياسة الخصوصية هذه. إذا كانت لديك أي أسئلة
                  أو مخاوف بشأن كيفية تعاملنا مع بياناتك، فيرجى الاتصال بنا
                  للحصول على مزيد من المساعدة.
                </p>
                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  نحن ملتزمون بالتمسك بأعلى معايير حماية الخصوصية وسنستمر في
                  تحديث سياساتنا وفقًا للقوانين واللوائح ذات الصلة.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function AboutAreaEnglish() {
  return (
    <>
      <div className="about__area about__area_one p-relative pt---100  pb---120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="about__content">
                <h2 className="about__title">Refund Policy:</h2>
                <p> Last updated: October, 2023</p>
                <hr />
                <br />

                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  At spacecavez , we strive to provide the best learning
                  experience for our users. However, we understand that there
                  may be instances where a refund is necessary.
                </p>
                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  Refund requests will be considered on a case-by-case basis and
                  must be submitted within 30 days of the original purchase. To
                  request a refund, please contact our customer support team at{" "}
                  <a href="mailto:info@spacecavez.com">info@spacecavez.com</a>{" "}
                  with your order details and reason for the refund request.
                </p>

                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  Please note that refunds will not be provided for digital
                  products that have been accessed or downloaded, unless there
                  is a technical issue that prevents access to the content.
                </p>
                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  We are committed to resolving any issues and ensuring customer
                  satisfaction. If you have any concerns about your purchase,
                  please reach out to us and we will do our best to assist you.
                </p>

                <h2 className="about__title">Privacy Policy:</h2>

                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  At spacecavez, we take your privacy and data security seriously. We are committed to protecting the personal information of our users and maintaining transparency in how we collect, use, and store data.

                </p>

                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                 When you use our platform, we may collect certain personal information such as your name, email address, and payment details. This information is used solely for the purpose of providing you with access to our educational content and processing transactions.

                </p>

                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                  We do not sell or share your personal information with third parties without your consent. We use industry-standard security measures to protect your data from unauthorized access or disclosure.

                </p>
                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                 Our platform may use cookies and similar technologies to enhance your user experience and track usage patterns. You have the option to manage cookie preferences through your browser settings.

                </p>
                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
                 By using our platform, you consent to the collection and use of your personal information as outlined in this privacy policy. If you have any questions or concerns about how we handle your data, please contact us for further assistance.
                </p>
                <p
                  className="about__paragraph"
                  style={{ textAlign: "justify" }}
                >
               We are committed to upholding the highest standards of privacy protection and will continue to update our policies in accordance with relevant laws and regulations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
