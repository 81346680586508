import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Authintcation() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);

  return <>{isArlanguage ? <AuthintcationArabic /> : <AuthintcationEnglsh />}</>;
}

function AuthintcationArabic() {
  return (
    <>
      <div className="authincation h-100 p-meddle">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center ">
            <div className="col-md-5">
              <div className="form-input-content text-center error-page">
                <h3 className="error-text font-weight-bold">سجل الدخول اولا</h3>
                <h4>للوصول الي الصفحة المطلوبة يجب تسجيل الدخول اولا</h4>

                <div>
                  <Link className="btn btn-danger" to={"/login"}>
                    تسجيل الدخول
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function AuthintcationEnglsh() {
  return (
    <>
      <div className="authincation h-100 p-meddle">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center ">
            <div className="col-md-5">
              <div className="form-input-content text-center error-page">
                <h3 className="error-text font-weight-bold">Login Frist</h3>
                <h4>To Reach The Required Page You Have To Login Frist</h4>

                <div>
                  <Link className="btn btn-danger" to={"/login"}>
                    Login Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
