import { Link } from "react-router-dom";

export default function BTN({url,classes,title}) {
  return (
    <>
      <Link to={url} className={classes}>
        {title}
      </Link>
    </>
  );
}
