import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from 'react-bootstrap/Alert';
import { contactApi } from "../../../store/contactFormSclicer";

export default function ContactSection() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);
  return (
    <>{isArlanguage ? <ContactSectionArabic /> : <ContactSectionEnglish />}</>
  );
}

function ContactSectionArabic() {
  const {loading,error} = useSelector(state=>state.contactForm);
  const dispatch = useDispatch();
  const [infroMassage ,setInfroMassage] = useState();

  const [fullName,setFullName] = useState();
  const [email,setEmail] = useState();
  const [phone,setPhone] = useState();
  const [subject,setSubject] = useState();
  const [userMessage,setUserMessage] = useState();

  const handleSubmit = (e)=>{
    e.preventDefault();

    const objectData = {
      name : fullName,
      email : email,
      phone : phone,
      subject : subject,
      message : userMessage
  }

    dispatch(contactApi(objectData)).then(result=>{
      if (result.payload.message === "Data sent successfully") {
        setInfroMassage("تم ارسال الرسالة بنجاح , شكرا لك");
        setFullName('')
        setEmail('')
        setPhone('')
        setSubject('')
        setUserMessage('')

        const timerFun = setTimeout(()=>{
          setInfroMassage('');
          clearTimeout(timerFun);
        },5000)
      }
    })



  }




  return (
    <>
      <div id="react-contact" className="react-contact-page  pt---140">
        <div className="container">
          <div className="row pb---96">
            <div className="col-lg-10 m-auto  col-sm-12">
              {/*================= Form Section Start Here =================*/}
              <div className="react-blog-form">
                <h2 className="contact-title">
                ألديك الأسئلة ؟ <br />
                  لا تتردد في التواصل معنا
                </h2>
                <div id="blog-form" className="blog-form">
                  <div id="form-messages" />
                  <form
                    id="contact-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="back-input">
                          <input
                          onChange={(e)=>{
                            setFullName(e.target.value)
                          }}
                          value={fullName}
                            required
                            id="name"
                            type="text"
                            name="name"
                            placeholder="الإسم بالكامل"
                            min={3}
                            max={200}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pdl-5">
                        <div className="back-input">
                          <input
                           onChange={(e)=>{
                            setEmail(e.target.value)
                          }}
                          value={email}
                            required
                            id="email"
                            type="email"
                            name="email"
                            placeholder="البريد الإلكتروني"
                            min={3}
                            max={200}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="back-input">
                          <input
                           onChange={(e)=>{
                            setSubject(e.target.value)
                          }}
                          value={subject}
                            required
                            id="subject"
                            type="text"
                            name="subject"
                            placeholder="الموضوع"
                            min={3}
                            max={200}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pdl-5">
                        <div className="back-input">
                          <input
                           onChange={(e)=>{
                            setPhone(e.target.value)
                          }}
                          value={phone}
                            style={{ direction: "rtl" }}
                            required
                            maxLength={11}
                            id="phone"
                            type="tel"
                            name="phone"
                            placeholder="رقم الهاتف"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="back-textarea">
                          <textarea
                           onChange={(e)=>{
                            setUserMessage(e.target.value)
                          }}
                          value={userMessage}
                            id="message"
                            name="message"
                            placeholder="رسالتك"
                            defaultValue={""}
                            min={3}
                            max={3000}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                      {loading?<>
                        <button disabled className="back-btn">
                        جاري الارسال .....
                       
                        </button>
                      </>:<>
                      <button type="submit" className="back-btn">
                          ارسال
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right"
                          >
                            <line x1={5} y1={12} x2={19} y2={12} />
                            <polyline points="12 5 19 12 12 19" />
                          </svg>
                        </button>
                      </>}
                       
                      </div>
                    </div>
                  </form>
                  {error&&<>
                    <div className="col-lg-12 mt-3">
                  <Alert  variant='danger'>
                    {error}
                  </Alert>
                  </div>
                  </>}
                  {infroMassage&&<>
                    <div className="col-lg-12 mt-3">
                  <Alert  variant='success'>
                    {infroMassage}
                  </Alert>
                  </div>
                  </>}
                  
                </div>
              </div>
              {/*================= Form Section End Here =================*/}
            </div>
          </div>
          {/* <div className="row pb---110">
            <div className="col-lg-12">
             <div className="react-contacts pt-106">                 
                <div className="react-image-maping">
                  <img src="assets/images/contact/1.jpg" alt="Map" />
                  <div className="react-ripple react-tooltip1"><div className="box"><span>New York</span></div></div>
                </div>
              </div>                  
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

function ContactSectionEnglish() {
  const {loading,error} = useSelector(state=>state.contactForm);
  const dispatch = useDispatch();
  const [infroMassage ,setInfroMassage] = useState();

  const [fullName,setFullName] = useState();
  const [email,setEmail] = useState();
  const [phone,setPhone] = useState();
  const [subject,setSubject] = useState();
  const [userMessage,setUserMessage] = useState();

  const handleSubmit = (e)=>{
    e.preventDefault();

    const objectData = {
      name : fullName,
      email : email,
      phone : phone,
      subject : subject,
      message : userMessage
  }

    dispatch(contactApi(objectData)).then(result=>{
      if (result.payload.message === "Data sent successfully") {
        setInfroMassage("The message was sent successfully, thank you.");
        setFullName('')
        setEmail('')
        setPhone('')
        setSubject('')
        setUserMessage('')

        const timerFun = setTimeout(()=>{
          setInfroMassage('');
          clearTimeout(timerFun);
        },5000)
      }
    })



  }





  return (
    <>
      <div id="react-contact" className="react-contact-page  pt---140">
        <div className="container">
          <div className="row pb---96">
            <div className="col-lg-10 m-auto  col-sm-12">
              <div className="react-blog-form">
                <h2 className="contact-title">
                  Have Questions
                  <br />
                  Conatct Us Now
                </h2>
                <div id="blog-form" className="blog-form">
                  <div id="form-messages" />
                  <form
                    id="contact-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="back-input">
                          <input
                          onChange={(e)=>{
                            setFullName(e.target.value)
                          }}
                          value={fullName}
                            required
                            id="name"
                            type="text"
                            name="name"
                            placeholder="Full Name"
                            min={3}
                            max={200}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pdl-5">
                        <div className="back-input">
                          <input
                           onChange={(e)=>{
                            setEmail(e.target.value)
                          }}
                          value={email}
                            required
                            id="email"
                            type="email"
                            name="email"
                            placeholder="Email"
                            min={3}
                            max={200}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="back-input">
                          <input
                           onChange={(e)=>{
                            setSubject(e.target.value)
                          }}
                          value={subject}
                            required
                            id="subject"
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            min={3}
                            max={200}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pdl-5">
                        <div className="back-input">
                          <input
                            onChange={(e)=>{
                            setPhone(e.target.value)
                          }}
                          value={phone}
                            required
                            maxLength={11}
                            id="phone"
                            type="tel"
                            name="phone"
                            placeholder="Phone Number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="back-textarea">
                          <textarea
                           onChange={(e)=>{
                            setUserMessage(e.target.value)
                          }}
                          value={userMessage}
                          min={3}
                            max={3000}
                            required
                            id="message"
                            name="message"
                            placeholder="Message"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                      {loading?<>
                        <button className="back-btn">
                       
                          Loading....
                        </button>
                      </>:<>
                      <button type="submit" className="back-btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right"
                          >
                            <line x1={5} y1={12} x2={19} y2={12} />
                            <polyline points="12 5 19 12 12 19" />
                          </svg>
                          Send
                        </button>
                      </>}
                        
                      </div>
                    </div>
                  </form>
                  {error&&<>
                    <div className="col-lg-12 mt-3">
                  <Alert  variant='danger'>
                    {error}
                  </Alert>
                  </div>
                  </>}
                  {infroMassage&&<>
                    <div className="col-lg-12 mt-3">
                  <Alert  variant='success'>
                    {infroMassage}
                  </Alert>
                  </div>
                  </>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
