import ProfileComponent from "./component/ProfileComponent";
import { useEffect, useState } from "react";
import Authintcation from "../../component/Authintcation";
import { useLocation, useNavigate } from "react-router";
import CryptoJS, { SHA256, enc } from "crypto-js";
import { getCoursesFromApi, getStudentCourseApi } from "../../store/getStudentCoursesSlicer";
import { useDispatch } from "react-redux";

function responseFrontEnd(data) {

  let hmac = "";
  let hmacString = "";

  Object.keys(data).forEach(function (key) {
    if (key == "hmac") {
      hmac = data[key];
    } else {
      if (
        key == "amount_cents" ||
        key == "created_at" ||
        key == "currency" ||
        key == "error_occured" ||
        key == "has_parent_transaction" ||
        key == "id" ||
        key == "integration_id" ||
        key == "is_3d_secure" ||
        key == "is_auth" ||
        key == "is_capture" ||
        key == "is_refunded" ||
        key == "is_standalone_payment" ||
        key == "is_voided" ||
        key == "order" ||
        key == "owner" ||
        key == "pending" ||
        key == "source_data.pan" ||
        key == "source_data.sub_type" ||
        key == "source_data.type" ||
        key == "success"
      ) {
        hmacString += data[key];
      }
    }
  });

  const key = "3319ECA38C47AAF4A9B0EFA4AE98C9BB";

  const hashedHMACString = signature222(key, hmacString);
  if (hashedHMACString === hmac) {
    window.alert("Prosses Doen Sucessfully");
    return true;
  }
  window.alert("Some Error Happen Get in Touch With Supported Team");
  return false
}

function signature222(key, data) {
  const hmac = CryptoJS.HmacSHA512(data, key);

  return CryptoJS.enc.Hex.stringify(hmac);
}

export default function Profile() {
  const navegator = useNavigate()
  const { state } = useLocation();
  const data = JSON.parse(localStorage.getItem("userData"));


  useEffect(() => {
    window.scrollTo(0, 0);
    if (state !=null) {
      const isDone = responseFrontEnd(state.query);
      if(isDone){
        navegator('/profile')
      }
    }
  }, []);
  return (
    <>
      {data !== null ? (
        <ProfileComponent userdata={data.data} />
      ) : (
        <>
          <Authintcation />
        </>
      )}
    </>
  );
}
