import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Error() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);
  return <>{isArlanguage ? <ErrorArabic /> : <ErrorEnglish />}</>;
}

function ErrorArabic() {
  return<>
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center ">
          <div className="col-md-5">
            <div className="form-input-content text-center error-page">
              <h1 className="error-text font-weight-bold">404</h1>
              <h4>
                <i className="fa fa-exclamation-triangle text-warning" />
                 الصفحة
                التي تحاول الوصول اليها ليست متاحة الان
              </h4>
              <p>قد تكون اخطات في كتابة عنوان الصفحة</p>
              <div>
                <Link className="btn btn-danger" to="/">
                  الرجوع الي الصفحة الرئيسية
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
}
function ErrorEnglish() {
    return<>
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center ">
          <div className="col-md-5">
            <div className="form-input-content text-center error-page">
              <h1 className="error-text font-weight-bold">404</h1>
              <h4>
                <i className="fa fa-exclamation-triangle text-warning" /> The
                page you were looking for is not found!
              </h4>
              <p>
                You may have mistyped the address or the page may have moved.
              </p>
              <div>
                <Link className="btn btn-danger" to="/">
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
}
