import { useEffect, useState } from "react";
import Btn from "../../../component/sub-components/Btn";
import { useSelector } from "react-redux";
import {
  ab,
  ab3,
  about2,
  about22,
  badge,
  comment,
  dot,
  dots1,
  dots2,
  shape_01,
  shape_02,
  shape_03,
  shape_044,
  signature,
} from "../../../Images/about/index";
import aboutimg from "../../../Images/about/ABOUT.png";

export default function AboutArea() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);
  return <>{lang === "ar" ? <AboutAreaArabic /> : <AboutAreaEnglish />}</>;
}

function AboutAreaArabic() {
  return (
    <>
      <div className="about__area about__area_one p-relative pt---140  pb---120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about__image">
                <img src={aboutimg} alt="About" />
                {/* <img
                  className="react__shape__ab"
                  src={badge}
                  alt="Shape Image"
                /> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about__content">
                <h2 className="about__title">
                  اهلا ومرحبا بك
                  <br />
                  في منصة spacecavez التعلمية
                </h2>
                <p className="about__paragraph">
                
                    هي اول منصة عربية لعلوم الفضاء فالشرق الأوسط وشمال أفريقيا ، مهمتنا هي جعل علوم وتكنولوچيا الفضاء اكثر اتاحة وتوافرا للجميع .
                </p>
                

                <ul>
                  <li className="last-li">
                    <em>راسلنا علي </em>
                    <a href="mailto:info@spacecavez.com">info@spacecavez.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function AboutAreaEnglish() {
  return (
    <>
      <div className="about__area about__area_one p-relative pt---140  pb---120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about__image">
                <img src={aboutimg} alt="About" />
                {/* <img
                  className="react__shape__ab"
                  src={badge}
                  alt="Shape Image"
                /> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about__content">
                <h2 className="about__title">
                  Welcome to <br /> Spacecavez Education Platform
                </h2>
                <p className="about__paragraph">
                Spacecavez is the first Arabic space education platform in MENA region, dedicated to making space education more accessible and affordable for everyone.
                </p>

               
                <ul>
                  <li className="last-li">
                    <em>Get Support</em>
                    <a href="mailto:info@spacecavez.com">info@spacecavez.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
