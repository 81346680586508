import { configureStore } from "@reduxjs/toolkit";
import langReducer from './langSlicer'
import userDataSlicer from "./userDataSlicer";
import addedUserSlicer from "./addedUserSlicer";
import TheamSlicer from "./TheamSlicer";
import AuthIsLoginSlicer from "./AuthIsLoginSlicer";
import getAllCoursesSlicer from "./getAllCoursesSlicer";
import getCourseByIdSlicer from "./getCourseByIdSlicer";
import getAllInstractorsSlicer from "./getAllInstractorsSlicer";
import getInstractorByIdSlicer from "./getInstractorByIdSlicer";
import contactFormSclicer from "./contactFormSclicer";
import joinInstrsctorFormSclicer from "./joinInstrsctorFormSclicer";
import paymentPaymobSlicer from "./paymentPaymobSlicer";
import getStudentCoursesSlicer from "./getStudentCoursesSlicer";
import getAllLessonsSlicer from "./getAllLessonsSlicer";
import getSingleLessonsSlicer from "./getSingleLessonsSlicer";
import forgetPasswordSlicer from "./forgetPasswordSlicer";
import resetPasswordSlicer from "./resetPassowrdSlicer";
 const store = configureStore({
    reducer:{
        lang : langReducer,
        theam : TheamSlicer,
        
        userData : userDataSlicer,
        createUser : addedUserSlicer,
        auth: AuthIsLoginSlicer, 
        forgetPassword: forgetPasswordSlicer,
        resetPassword: resetPasswordSlicer,

        getCourses:getAllCoursesSlicer,
        getCourseById:getCourseByIdSlicer,
        
        getStudentCourses:getStudentCoursesSlicer,
        getCoursesLessons: getAllLessonsSlicer,

        getSingleLessons: getSingleLessonsSlicer,

        getInstractors:getAllInstractorsSlicer,
        getInstractorById:getInstractorByIdSlicer,

        contactForm:contactFormSclicer,
        joinForm:joinInstrsctorFormSclicer,

        paymobpayment:paymentPaymobSlicer ,
    }
})

export default store