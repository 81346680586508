import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../../store/userDataSlicer";
import { LogedIn } from "../../../store/AuthIsLoginSlicer";
import Loader from "../../../component/Loader";
import { forgetPasswordApi } from "../../../store/forgetPasswordSlicer";

export default function ForgetPassordForm() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);
  const { loading, res, error } = useSelector((s) => s.forgetPassword);
  const dispatch = useDispatch();

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);

  const [userEmail, setUserEmail] = useState("");
  const [userEmailError, setUserEmailError] = useState("");

  const handleSubmite = (e) => {
    e.preventDefault();
    if (userEmailError === "") {
      const userCredintianals = {
        email: userEmail,
      };
      dispatch(forgetPasswordApi(userCredintianals));
    }
  };

  return (
    <>
      <div
        className="react-login-page react-signup-page pt---150 pb---120 d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-right-form">
                <form onSubmit={handleSubmite}>
                  <div className="login-top">
                    <div className="logo">
                      <h2>
                        {lang === "ar" ? "نسيت كلمة المرور" : "Forget Password"}
                      </h2>
                    </div>
                  </div>
                  <p>
                    <label>
                      {lang === "ar" ? "البريد الالكتروني" : "Email"}
                    </label>
                    <input
                      placeholder={
                        lang === "ar" ? "ادخل بريدك الالكتروني" : "Enter Email"
                      }
                      type="email"
                      name="email"
                      required
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          setUserEmailError(() => {
                            return lang === "ar"
                              ? " بريد الكتروني غير صالح"
                              : "ُInvaild Email";
                          });
                        } else {
                          setUserEmailError("");
                        }
                        setUserEmail(e.target.value);
                      }}
                      value={userEmail}
                    />
                  </p>
                  {userEmailError && (
                    <Alert variant="danger" className="mt-2">
                      {userEmailError}
                    </Alert>
                  )}

                  {loading ? (
                    <>
                      <button type="submit" id="button" name="submit" disabled>
                        <Loader />
                      </button>
                    </>
                  ) : (
                    <>
                      {lang === "ar" ? (
                        <button type="submit" id="button" name="submit">
                          ارسال
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right"
                          >
                            <line x1={5} y1={12} x2={19} y2={12} />
                            <polyline points="12 5 19 12 12 19" />
                          </svg>
                        </button>
                      ) : (
                        <button type="submit" id="button" name="submit">
                          Send Email
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right"
                            style={{ rotate: "180deg" }}
                          >
                            <line x1={5} y1={12} x2={19} y2={12} />
                            <polyline points="12 5 19 12 12 19" />
                          </svg>
                        </button>
                      )}
                    </>
                  )}
                  {res === "email send" && (
                    <Alert variant="success" className="mt-2">
                   {lang === "ar"
                              ? "تحقق من البريد الالكتروني"                              
                              : "Please Check Your Email"}
                    </Alert>
                  ) }
                  {error && (
                    <Alert variant="danger" className="mt-2">
                  
                      {error}
              
                    </Alert>
                  ) }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
