import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import avatarMain from "../../../Images/avatar/avatar-main.png";
import IMG from "../../../component/ui/IMG";

export default function CoursesCard({tag,title,imgurl,price,id}) {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);

  
  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);


    return <>
         <div className="single-studies col-lg-4 grid-item">
              <div className="inner-course">
                <div className="case-img">
                  {/* <a href="#" className="cate-w">
                     {tag && tag} 
                     <img src="assets/images/course/beg.png" alt={tag} />
                  </a> */}
                  {imgurl ? (
                <IMG src={imgurl} title={title} />
              ) : (
                <img src={avatarMain} alt="profile" />
              )}
              {/* <img src={avatarMain} alt="profile" /> */}
                </div>
                <div className="case-content">
                  <h4 className="case-title">
                   <Link  to={`/single/${id}`}>
                  {title}
                   </Link> 
                    
                  </h4>
                  <ul className="react-ratings">
                    <li className="react-book">
                  <Link to={`/single/${id}`} className="btn btn-danger p-2" style={{color:"var( --cutom-text-color) !important"}}>
                  {isArlanguage? "عرض الان":"view now"} 
                  </Link>
                    </li>
                    <li className="price">{price/100} {isArlanguage? "جنيها":"EG"}  </li>
                  </ul>
                </div>
              </div>
            </div>
    </>
};
