import { useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function ErrorStatment() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);
  return (
    <>
      {isArlanguage ? (
        <div className="react-course-filter back__course__page_grid pt---140 pt---110" style={{
            minHeight: "100vh",
          }}>
          <div className="container pb---70">
            <div className="row">
              <div className="col-12 text-center">
                <h3>
                  حدث خطا اثناء الاتصال بالسيرفر , يرجي المحاولة في وقت لاحق{" "}
                </h3>
                <Link className="btn btn-danger" to={"/"}>
                  الصفحة الرئيسية
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="react-course-filter back__course__page_grid pt---140 pt---110">
          <div className="container pb---70">
            <div className="row">
              <div className="col-12 text-center">
                <h3>Some Error Happen, Try Again Later</h3>
                <Link className="btn btn-danger" to={"/"}>
                  Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
