import LoginForm from "./component/LoginForm";

import { useEffect } from "react";

export default function Login() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
    return <>
  
          <LoginForm />
       
    </>
};
