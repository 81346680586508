import Footer from "../../component/Footer";
import HeaderTop from "../../component/HeaderTop";
import Wrapper from "../../component/Wrapper";
import { useEffect } from "react";
import InstractorForm from "./component/InstractorForm";

export default function Instractor() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      
        <InstractorForm />
     
    </>
  );
}
