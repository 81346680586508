import UserCart from "./component/UserCart";
import { useEffect, useState } from "react";
import Authintcation from "../../component/Authintcation";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCourse } from "../../store/getCourseByIdSlicer";
import Loader from "../../component/Loader";
import ErrorStatment from "../../component/ui/ErrorStatment";

export default function ShoppingCard() {
  const data = JSON.parse(localStorage.getItem("userData"));
  const {id} = useParams();
  const { loading, error } = useSelector((state) => state.getCourseById);
  const dispatch = useDispatch();
  const [courseData, setCourseData] = useState();

  useEffect(() => {
    dispatch(getCourse(id)).then((result) => {
        setCourseData(result.payload.data);
      
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {data !== null ? 
      (
      <>
      {loading ? (
        <div className="react-course-filter back__course__page_grid pt---140 pt---110">
          <div className="container pb---70">
            <div className="row">
              <div className="col-12 text-center">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {error ? (
            <>
          
             <ErrorStatment />
            
            </>
          ) : (
            <>
              {courseData && (
                
                <UserCart  data={courseData}/>
              )}
            </>
          )}
        </>
      )}
      </>  
       
      ) : 
      (
        <>
          <Authintcation />
        </>
      )}
    </>
  );
}
