import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Loader from "../../../component/Loader";
import { Alert } from "react-bootstrap";
import { resetPassworddApi } from "../../../store/resetPassowrdSlicer";

export default function ResetPasswordForm({token}) {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);

  const { loading, error } = useSelector((s) => s.resetPassword);
  const navegate = useNavigate();
  const dispatch = useDispatch();

  const decoded = jwtDecode(token);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [conformPassword, setConformPassword] = useState("");
  const [conformPasswordError, setConformPasswordError] = useState("");

  const handleFormData = (e) => {
    e.preventDefault();
    if (password === conformPassword) {
      const userCredentials = {
        newPassword: password,
        userid: decoded.userId,
      };

      dispatch(resetPassworddApi(userCredentials)).then((res) => {
        if (res.payload.message === 'Password updated Successfully') {
            window.alert("Password updated Successfully");
          navegate("/login");
        } 
      });
    } 
  };

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);
  return (
    <>
      <div
        className="react-login-page react-signup-page pt---150 pb---120 d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-right-form">
                <form onSubmit={handleFormData}>
                  <div className="login-top">
                    <div className="logo">
                      <h3>
                        {lang === "ar" ? "اعادة تعين كلمة المرور" : "Reset Password"}
                      </h3>
                    </div>
                  </div>
                  <p>
                    <label>
                      {lang === "ar" ? "كلمة المرور الجديدة" : "New Password"}
                    </label>
                    <input
                      placeholder={
                        lang === "ar" ? "كلمة المرور الجديد" : "ُEnter New Password"
                      }
                      type="password"
                      required
                      onChange={(e) => {
                        if (e.target.value.length < 8) {
                            setPasswordError(() => {
                            return lang === "ar"
                              ? "كلمة المرور يجب ان تكون علي الاقل 8 احرف"
                              : "ُPassword must be at least 8 characters";
                          });
                        } else {
                            setPasswordError("");
                        }
                        setPassword(e.target.value);
                      }}
                      value={password}
                    />
                  </p>
                  {passwordError && (
                    <Alert variant="danger" className="mt-2">
                      {passwordError}
                    </Alert>
                  )}
                  <p>
                    <label>
                      {lang === "ar" ? "اعادة ادخال كلمة المرور" : "Conform Password"}
                    </label>
                    <input
                      placeholder={
                        lang === "ar" ? "كلمة المرور " : "ُRe-enter Password"
                      }
                      type="password"
                      required
                      onChange={(e) => {
                        if (e.target.value != password) {
                            setConformPasswordError(() => {
                            return lang === "ar"
                              ? "كلمة المرور غير متطابقة"
                              : "ُPassword is not matching";
                          });
                        } else {
                            setConformPasswordError("");
                        }
                        setConformPassword(e.target.value);
                      }}
                      value={conformPassword}
                    />
                  </p>
                  {conformPasswordError && (
                    <Alert variant="danger" className="mt-2">
                      {conformPasswordError}
                    </Alert>
                  )}

                  {password == conformPassword&& <>
                    <button type="submit" id="button" name="submit">
                   {loading ? <>
                    <Loader />
                   </>:<>
                   {lang === "ar" ? <>
                      تأكيد
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right"
                          >
                            <line x1={5} y1={12} x2={19} y2={12} />
                            <polyline points="12 5 19 12 12 19" />
                          </svg>
                        </> :<>
                        Reest
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right"
                            style={{ rotate: "180deg" }}
                          >
                            <line x1={5} y1={12} x2={19} y2={12} />
                            <polyline points="12 5 19 12 12 19" />
                          </svg>
                        </>}

                   </>}


                        </button>
                  </>}
                  {error && (
                    <Alert variant="danger" className="mt-2">
                      {error}
                    </Alert>
                  )} 

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
