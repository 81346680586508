import { Link, useNavigate, useParams } from "react-router-dom";

// image
import featured_01 from "../../Images/featured/1.png";
import paymob from "../../Images/payment/paymobLogo.png";
import fawray from "../../Images/payment/FawryPay_Logo.png";

import { Form, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCourse } from "../../store/getCourseByIdSlicer";
import Loader from "../../component/Loader";
import IMG from "../../component/ui/IMG";
import CryptoJS, { SHA256, enc } from "crypto-js";
import { paymentPaymobApi } from "../../store/paymentPaymobSlicer";



export default function Payment() {
  const { loading, date, error } = useSelector((state) => state.getCourseById);
  const dispatch = useDispatch();
  const [courseData, setCourseData] = useState();
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);
  const { id } = useParams();

  useEffect(() => {
    if (courseData) {
      return;
    }
    dispatch(getCourse(id)).then((result) => {
      if (result.payload.message === "fetched successfully") {
        setCourseData(result.payload.data);
      }
    });
  }, [courseData]);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);

  return (
    <>
      {loading ? (
        <>
          <div className="react-course-filter back__course__page_grid pt---140 pt---110">
            <div className="container pb---70">
              <div className="row">
                <div className="col-12 text-center">
                  <Loader />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>
              <div className="react-course-filter back__course__page_grid pt---140 pt---110">
                <div className="container pb---70">
                  <div className="row">
                    <div className="col-12 text-center">
                      <h2>{error}</h2>
                      <Link className="btn btn-danger" to={"/"}>
                        Home
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {courseData && (
                <>
                  {isArlanguage ? (
                    <PaymentArabic data={courseData} />
                  ) : (
                    <PaymentEnglish data={courseData} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );

  return <></>;
}

function PaymentArabic({ data }) {
  const dispatch = useDispatch();

  const handlePaymobPage = () => {
    dispatch(paymentPaymobApi(data.course._id)).then((res) => {
      console.log(res.payload);
      if (res.payload.iframe_url) {
        window.open(res.payload.iframe_url, "_blank", "noreferrer");
      } else {
        console.log(res.payload.message);
      }
    });
  };

  return (
    <>
      <div className="cart__user__area pt---140 pb---90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="text-right clr-red"> مراجعة عملية الشراء</h3>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <ul className="pb-3">
                <li className="d-flex justify-content-between align-items-center  clr-red">
                  <span>المبلغ الكلي :</span>
                  <span className="col-cus-font">
                    {data.course.price / 100} جنيها
                  </span>
                </li>
                {/* <li className="d-flex justify-content-between align-items-center  clr-red">
                  <span>الخصم :</span>
                  <span className="col-cus-font">200 جنيها </span>
                </li> */}
                <hr />
              </ul>
              <div>
                <ul>
                  <li className="d-flex justify-content-between align-items-center  clr-red">
                    <h5 className=" clr-red">المبلغ الكلي :</h5>
                    <h3 className="col-cus-font">
                      {" "}
                      {data.course.price / 100} جنيها{" "}
                    </h3>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 px-2">
              <div className="row">
                <div className="col-12">
                  <h3 className="col-cus-font">وسيلة الدفع </h3>
                  <div className="row d-flex gap-3">
                    <button
                      className="col-lg-5 col-md-6 col-sm-12 card clr-red d-flex align-items-center justify-content-center flex-column p-4"
                      onClick={handlePaymobPage}
                    >
                      <img src={paymob} alt="paymob logo" width={300} />
                      <p>PAYMOB</p>
                    </button>

                    {/* <div className="col-lg-5 col-md-6 col-sm-12 card clr-red">
                      <div
                        className="card-header d-flex align-items-center justify-content-between flex-row"
                        style={{
                          backgroundColor: "transparent !important",
                          border: "none !important",
                        }}
                      >
                        <span>فوري</span>
                        <img src={fawray} width={100} />
                      </div>
                      <div className="card-body">
                        <form>
                          <div class="form-floating mb-3">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingInput"
                              placeholder="01XX XXXX XXX"
                            />
                            <label for="floatingInput">01XX XXXX XXX</label>
                          </div>

                          <div>
                            <button className="btn btn-danger my-1">
                              تاكيد
                            </button>
                          </div>
                        </form>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div>
                  <p className="col-cus-font pt-3">ملخص المشتريات</p>
                  <div className="row my-2">
                    <div className="col-3">
                      {<IMG src={data.course.image} title={data.course.name.en} />}
                    </div>
                    <div className="col-7">
                      <div className="card-body">
                        <h5 className="card-title">{data.course.name.ar}</h5>
                        <p
                          className="card-text"
                          style={{
                            width: "100%",
                            height: "50px",
                            textOverflow: "ellipsis",
                            fontSize: "10px",
                            overflow: "hidden",
                          }}
                        >
                          {data.course.description.ar}
                        </p>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="card-body">
                        <h5 className="card-text ">
                          {data.course.price / 100} جنيها{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function PaymentEnglish({ data }) {
  const dispatch = useDispatch();

  const handlePaymobPage = () => {
    dispatch(paymentPaymobApi(data.course._id)).then((res) => {
      console.log(res.payload);
      if (res.payload.iframe_url) {
        window.open(res.payload.iframe_url, "_blank", "noreferrer");
      } else {
        console.log(res.payload.message);
      }
    });
  };

  return (
    <>
      <div className="cart__user__area pt---140 pb---90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className=" clr-red"> Checkout</h3>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <ul className="pb-3">
                <li className="d-flex justify-content-between align-items-center  clr-red">
                  <span>Original Price :</span>
                  <span className="col-cus-font">
                    {data.course.price / 100} EG
                  </span>
                </li>
                {/* <li className="d-flex justify-content-between align-items-center  clr-red">
                  <span>Discount :</span>
                  <span className="col-cus-font">200 EG </span>
                </li> */}
                <hr />
              </ul>
              <div>
                <ul>
                  <li className="d-flex justify-content-between align-items-center  clr-red">
                    <h5 className=" clr-red">Total Amount :</h5>
                    <h3 className="col-cus-font">
                      {" "}
                      {data.course.price / 100} EG{" "}
                    </h3>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              <div className="row">
                <div className="col-12 px-3">
                  <h3 className="col-cus-font">Payment Method </h3>
                  <div className="row d-flex gap-3">
                    <button
                      className="col-lg-5 col-md-6 col-sm-12 card clr-red d-flex align-items-center justify-content-center flex-column p-3"
                      onClick={handlePaymobPage}
                    >
                      <img src={paymob} alt="paymob logo" width={300} />
                      <p>PAYMOB</p>
                    </button>

                    {/* <div className="col-lg-5 col-md-6 col-sm-12 card clr-red">
                      <div
                        className="card-header d-flex align-items-center justify-content-between flex-row"
                        style={{
                          backgroundColor: "transparent !important",
                          border: "none !important",
                        }}
                      >
                        <span>Fawray</span>
                        <img src={fawray} width={100} />
                      </div>
                      <div className="card-body">
                        <form>
                          <div class="form-floating mb-3">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingInput"
                              placeholder="01XX XXXX XXX"
                            />
                            <label for="floatingInput">01XX XXXX XXX</label>
                          </div>

                          <div>
                            <button className="btn btn-danger my-1">
                              complete
                            </button>
                          </div>
                        </form>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div>
                  <p className="col-cus-font pt-3">Summary List</p>
                  <div className="row my-2">
                    <div className="col-3">
                      {<IMG src={data.course.image} title={data.course.name.en} />}
                    </div>
                    <div className="col-7">
                      <div className="card-body">
                        <h5 className="card-title clr-red">
                          {data.course.name.en}
                        </h5>
                        <p
                          className="card-text"
                          style={{
                            width: "100%",
                            height: "50px",
                            textOverflow: "ellipsis",
                            fontSize: "10px",
                            overflow: "hidden",
                          }}
                        >
                          {data.course.description.en}
                        </p>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="card-body">
                        <h5 className="card-text ">
                          {data.course.price / 100} EG{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
