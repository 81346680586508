import { Children } from "react";

export default function Wrapper(props) {
  return (
    <>
      <div className="react-wrapper">
        <div className="react-wrapper-inner">{props.children}</div>
      </div>
    </>
  );
}
