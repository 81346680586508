import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getCoursesFromApi = createAsyncThunk(
  "getAllCourese",
  async (pagenumber) => {
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/courses/getAll?page=${pagenumber}`
    )
      .then((response) => response.json())
      .then((resulet) => {
        return resulet;
      })
      .catch((error) => {
        return error;
      });
    return request;
  }
);

const getAllCoursesSlicer = createSlice({
  name: "courses/getAll",
  initialState: {
    loading: false,
    date: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getCoursesFromApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCoursesFromApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "fetched successfully") {
        state.date = action.payload.data;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(getCoursesFromApi.rejected, (state, action) => {
      state.loading = false;
    
      state.error = action.payload.message;
    });
  },
});

export default getAllCoursesSlicer.reducer;
