import CoursesCardSection from "./component/CoursesCardSection";
import InsctractorsCardSection from "./component/InsctractorsCardSection";
import { useEffect } from "react";
import HeroHome from "./component/HeroHome";
import CryptoJS, { SHA256, enc } from "crypto-js";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router";

function responseFrontEnd() {
  const data = {
    obj: {
      id: 2556706,
      pending: false,
      amount_cents: 100,
      success: true,
      is_auth: false,
      is_capture: false,
      is_standalone_payment: true,
      is_voided: false,
      is_refunded: false,
      is_3d_secure: true,
      integration_id: 6741,
      profile_id: 4214,
      has_parent_transaction: false,
      order: {
        id: 4778239,
        created_at: "2020-03-25T18:36:05.494685",
        delivery_needed: true,
        merchant: {
          id: 4214,
          created_at: "2019-09-22T18:32:56.764441",
          phones: ["01032347111"],
          company_emails: ["fnjum@temp-link.net"],
          company_name: "Accept Payments",
          state: "",
          country: "EGY",
          city: "",
          postal_code: "",
          street: "",
        },
        collector: {
          id: 115,
          created_at: "2019-06-29T00:48:26.910433",
          phones: [],
          company_emails: [],
          company_name: "logix - test",
          state: "Heliopolis",
          country: "egypt",
          city: "cairo",
          postal_code: "123456",
          street: "Marghany",
        },
        amount_cents: 2000,
        shipping_data: {
          id: 2558893,
          first_name: "abdulrahman",
          last_name: "Khalifa",
          street: "Wadi el Nile",
          building: "5",
          floor: "11",
          apartment: "1565162",
          city: "Cairo",
          state: "Cairo",
          country: "EG",
          email: "abdulrahman@weaccept.co",
          phone_number: "01011994353",
          postal_code: "",
          extra_description: " ",
          shipping_method: "UNK",
          order_id: 4778239,
          order: 4778239,
        },
        shipping_details: {
          id: 1401,
          cash_on_delivery_amount: 0,
          cash_on_delivery_type: "Cash",
          latitude: null,
          longitude: null,
          is_same_day: 0,
          number_of_packages: 1,
          weight: 1,
          weight_unit: "Kilogram",
          length: 1,
          width: 1,
          height: 1,
          delivery_type: "PUD",
          return_type: null,
          order_id: 4778239,
          notes: "im so tired",
          order: 4778239,
        },
        currency: "EGP",
        is_payment_locked: false,
        is_return: false,
        is_cancel: false,
        is_returned: false,
        is_canceled: false,
        merchant_order_id: null,
        wallet_notification: null,
        paid_amount_cents: 100,
        notify_user_with_email: false,
        items: [],
        order_url: "https://accept.paymobsolutions.com/i/nYWD",
        commission_fees: 0,
        delivery_fees_cents: 0,
        delivery_vat_cents: 0,
        payment_method: "tbc",
        merchant_staff_tag: null,
        api_source: "OTHER",
        pickup_data: null,
        delivery_status: [],
      },
      created_at: "2020-03-25T18:39:44.719228",
      transaction_processed_callback_responses: [],
      currency: "EGP",
      source_data: {
        pan: "2346",
        type: "card",
        sub_type: "MasterCard",
      },
      api_source: "IFRAME",
      terminal_id: null,
      is_void: false,
      is_refund: false,
      data: {
        acq_response_code: "00",
        avs_acq_response_code: "Unsupported",
        klass: "VPCPayment",
        receipt_no: "008603626261",
        order_info: "claudette09@exa.com",
        message: "Approved",
        gateway_integration_pk: 6741,
        batch_no: "20200325",
        card_num: null,
        secure_hash:
          "832F4673452F9538CCD57D6B07B74183A0EEB1BEF7CA58704E31B244E8366549",
        avs_result_code: "Unsupported",
        card_type: "MC",
        merchant: "TEST999999EGP",
        created_at: "2020-03-25T16:40:37.127504",
        merchant_txn_ref: "6741_572e773a5a0f55ff8de91876075d023e",
        authorize_id: "626261",
        currency: "EGP",
        amount: "100",
        transaction_no: "2090026774",
        txn_response_code: "0",
        command: "pay",
      },
      is_hidden: false,
      payment_key_claims: {
        lock_order_when_paid: true,
        integration_id: 6741,
        billing_data: {
          email: "claudette09@exa.com",
          building: "8028",
          apartment: "803",
          street: "Ethan Land",
          country: "CR",
          state: "Utah",
          last_name: "Nicolas",
          first_name: "Clifford",
          postal_code: "01898",
          extra_description: "NA",
          phone_number: "+86(8)9135210487",
          floor: "42",
          city: "Jaskolskiburgh",
        },
        order_id: 4778239,
        user_id: 4705,
        pmk_ip: "197.57.37.135",
        exp: 1585157836,
        currency: "EGP",
        amount_cents: 100,
      },
      error_occured: false,
      is_live: false,
      other_endpoint_reference: null,
      refunded_amount_cents: 0,
      source_id: -1,
      is_captured: false,
      captured_amount: 0,
      merchant_staff_tag: null,
      owner: 4705,
      parent_transaction: null,
    },
    type: "TRANSACTION",
  };

  const hmac = data.obj.data.secure_hash;

  const amount_cents = data.obj.amount_cents;
  const created_at = data.obj.created_at;
  const currency = data.obj.currency;
  const error_occured = data.obj.error_occured;
  const has_parent_transaction = data.obj.has_parent_transaction;
  const id = data.obj.id;
  const integration_id = data.obj.integration_id;
  const is_3d_secure = data.obj.is_3d_secure;
  const is_auth = data.obj.is_auth;
  const is_capture = data.obj.is_capture;
  const is_refunded = data.obj.is_refunded;
  const is_standalois_capturene_payment = data.obj.is_standalone_payment;
  const is_voided = data.obj.is_voided;
  const order_id = data.obj.order.id;
  const owner = data.obj.owner;
  const pending = data.obj.pending;
  const source_data_pan = data.obj.source_data.pan;
  const source_data_sub_type = data.obj.source_data.sub_type;
  const source_data_type = data.obj.source_data.type;
  const success = data.obj.success;

  const HMACString =
    amount_cents +
    created_at +
    currency +
    error_occured +
    has_parent_transaction +
    id +
    integration_id +
    is_3d_secure +
    is_auth +
    is_capture +
    is_refunded +
    is_standalois_capturene_payment +
    is_voided +
    order_id +
    owner +
    pending +
    source_data_pan +
    source_data_sub_type +
    source_data_type +
    success;

  console.log(HMACString);
  const key = "3319ECA38C47AAF4A9B0EFA4AE98C9BB";
  const hashedHMACString = signature222(
    "DF42E0CDDDEABBC182E7297FC4C0206B",
    HMACString
  );
  console.log("Processed");
  console.log(hmac);
  console.log(hashedHMACString);
}

function signature222(key, data) {
  const hmac = CryptoJS.HmacSHA512(data, key);

  return CryptoJS.enc.Hex.stringify(hmac);
}

export default function Home() {
  const navegator = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  console.log(query);


  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(query).length ) {
      navegator('/profile',{state:{query}})
    }
  }, []);
  return (
    <>
      <HeroHome />
      <CoursesCardSection />
      <InsctractorsCardSection />
      {/* <HeroSlider />
       */}
    </>
  );
}
