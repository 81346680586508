import { createSlice } from "@reduxjs/toolkit";

const TheamSlicer = createSlice({
    name:'theam',
    initialState:{
        them :'light'
    },
    reducers:{
        ChangeTheam: (state)=>{
            
            if(state.them === 'light'){
                state.them ='dark';
                document.getElementsByTagName('body')[0].setAttribute('them-data',state.them);
            }
            else{
                state.them ='light' ;
                document.getElementsByTagName('body')[0].setAttribute('them-data',state.them);
            }
        },
    }
})

export const {ChangeTheam} = TheamSlicer.actions; 
export default TheamSlicer.reducer;