import { useEffect } from "react";
import PolicyArea from "./component/PolicyArea";

export default function Policy() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
  
       <PolicyArea />
     
    </>
  );
}
