import LessonsArea from "./component/LessonsArea";
import { useEffect } from "react";

import Authintcation from "../../component/Authintcation";
import { useParams } from "react-router";

export default function Lessons() {
  const data = JSON.parse(localStorage.getItem("userData"));
  const {id} = useParams()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {data !== null ? (
        <LessonsArea lessonId = {id} />
      ) : (
        <>
          <Authintcation />
        </>
      )}
    </>
  );
}
