import BTN from "../../../component/ui/BTN";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import {
  home4_statliteandearth,
  home4_suits,
} from "../../../Images/home4/index";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay, Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
// import { Autoplay, Pagination, Navigation } from 'swiper/modules';


// Import Swiper styles
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
export default function HeroHome() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);
  return (
    <>
      <div className="react-slider-part pt---50 pb---50">

    {isArlanguage? <HeroAr/> : <HeroEng/>}
      </div>
    </>
  );
}
function HeroAr() {
  return (
    <>
      <Swiper
        id="homeSliderSwiper"
        className="home-sliders home2"
        modules={[Autoplay,Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        loop={true}
        navigation
      >
        <SwiperSlide className="item single-slide ">
          <div className="container">
            <Row className="d-flex align-items-center flex-wrap mt-5">
              <div className="col-lg-6 col-sm-12 pt-3  ">
                <div className="content-part">
                  <span className="slider-pretitle">
                  استكشف الكون معنا اليوم   
                                 </span>
                  <h2 className="slider-title">
                  أول منصة عربية لعلوم الفضاء فالشرق الأوسط وشمال أفريقيا    
                                </h2>
                  <div className="slider-btn">
                    <BTN
                      url={"/courses"}
                      title={"استكشف المزيد"}
                      classes={"react-btn-border"}
                    />
                  </div>
                </div>
              </div>
              <div className="img-rotaion col-lg-6 col-sm-12 "></div>
            </Row>
          </div>
        </SwiperSlide>
        <SwiperSlide className="item single-slide ">
          <div className="container">
            <Row className="d-flex align-items-center flex-wrap mt-5">
              <div className="col-lg-6 col-sm-12 pt-3  ">
                <div className="content-part">
                  <span className="slider-pretitle">
                  اكتسب الخبرة العملية معنا                  </span>
                  <h2 className="slider-title">
                  العمل علي مشروعات حقيقية خلال رحلة التعلم                  </h2>
                  <div className="slider-btn">
                    {/* <a href="about.html" className="react-btn-border">
                        Admissions
                      </a> */}
                    <BTN
                      url={"/courses"}
                      title={"استكشف المزيد"}
                      classes={"react-btn-border"}
                    />
                  </div>
                </div>
              </div>
              <div className="img-rotaion col-lg-6 col-sm-12 ">
                <img src={home4_statliteandearth} alt="hero" />
              </div>
            </Row>
          </div>
        </SwiperSlide>
        <SwiperSlide className="item single-slide ">
          <div className="container">
            <Row className="d-flex align-items-center flex-wrap mt-5">
              <div className="col-lg-6 col-sm-12 pt-3  ">
                <div className="content-part">
                  <span className="slider-pretitle">
                  تعلم من الأفضل                  </span>
                  <h2 className="slider-title">
                  تعلم علي يد افضل العلماء والمهندسين في صناعة الفضاء                  </h2>
                  <div className="slider-btn">
                    {/* <a href="about.html" className="react-btn-border">
                        Admissions
                      </a> */}
                    <BTN
                      url={"/courses"}
                      title={"استكشف المزيد"}
                      classes={"react-btn-border"}
                    />
                  </div>
                </div>
              </div>
              <div className="img-rotaion col-lg-6 col-sm-12 ">
                <img src={home4_suits} alt="hero" />
              </div>
            </Row>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
function HeroEng() {
  return (
    <>
      <Swiper
       id="homeSliderSwiper"
        className="home-sliders home2"
        modules={[Autoplay,Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        loop={true}
        navigation
      >
        <SwiperSlide>
          <div className="item single-slide ">
            <div className="container">
              <Row className="d-flex align-items-center flex-wrap mt-5">

                <div className="col-lg-6 col-sm-12 pt-3 ">
                  <div className="content-part ">
                    <span className="slider-pretitle">Explore the universe with us today</span>
                    <h2 className="slider-title">
                    the first Arabic space education platform in MENA region.
                    </h2>
                    <div className="slider-btn">
                      <BTN
                        url={"/courses"}
                        title={"DISCOVER"}
                        classes={"react-btn-border"}
                      />
                    </div>
                  </div>
                </div>
                <div className="img-rotaion col-lg-6 col-sm-12 ">
                  {/* <img src="assets/images/home4/rocket.png" alt="hero" /> */}
                </div>
              </Row>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="item single-slide ">
            <div className="container">
              <Row className="d-flex align-items-center flex-wrap mt-5">

                <div className="col-lg-6 col-sm-12 pt-3">
                  <div className="content-part">
                    <span className="slider-pretitle"> Hands-On Experience</span>
                    <h2 className="slider-title">
                    working on real space projects while learning.
                    </h2>
                    <div className="slider-btn">
                      <BTN
                        url={"/courses"}
                        title={"DISCOVER"}
                        classes={"react-btn-border"}
                      />
                    </div>
                  </div>
                </div>
                <div className="img-rotaion col-lg-6 col-sm-12 ">
                  <img src={home4_statliteandearth} alt="hero" />
                </div>
              </Row>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="item single-slide ">
            <div className="container">
              <Row className="d-flex align-items-center flex-wrap mt-5">

                <div className="col-lg-6 col-sm-12 pt-3  ">
                  <div className="content-part">
                    <span className="slider-pretitle">   Learn From The Best</span>
                    <h2 className="slider-title">
                    our courses are tought by space industry professionals.
                    </h2>
                    <div className="slider-btn">
                      <BTN
                        url={"/courses"}
                        title={"DISCOVER"}
                        classes={"react-btn-border"}
                      />
                    </div>
                  </div>
                </div>
                <div className="img-rotaion col-lg-6 col-sm-12 ">
                  <img src={home4_suits} alt="hero" />
                </div>
              </Row>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
