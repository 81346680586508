import { Link } from "react-router-dom";
import CardCourseGrid from "../../../component/sub-components/CardCouseGrid";
import FilterComponent, {
  AllFiter,
} from "../../../component/sub-components/FilterComponent";
import CoursesCard from "./CoursesCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {coursesData} from '../../data/coureses'

//images
import coursefilter_01 from "../../../Images/course-filter/orbital1.png";
import { getCoursesFromApi } from "../../../store/getAllCoursesSlicer";
import Loader from "../../../component/Loader";
import ErrorStatment from "../../../component/ui/ErrorStatment";

export default function CoursePageGrid() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);
  const { loading, date, error } = useSelector((state) => state.getCourses);
  const dispatch = useDispatch();

  const [course, setCourse] = useState();

  useEffect(() => {
    dispatch(getCoursesFromApi("1")).then((resulet) => {
      setCourse(resulet.payload.data);
    });
  }, []);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);
  return (
    <>
      {loading ? (
        <div className="react-course-filter back__course__page_grid pt---140 pt---110">
          <div className="container pb---70">
            <div className="row">
              <div className="col-12 text-center">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {error ? (
            <>
              <ErrorStatment />
            </>
          ) : (
            <>
              {course && (
                <>
                  {isArlanguage ? (
                    <CoursePageGridArabic course={course} />
                  ) : (
                    <CoursePageGridEngish course={course} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

function CoursePageGridArabic({ course }) {
  return (
    <>
      <div className="react-course-filter back__course__page_grid pt---140 pt---110">
        <div className="container pb---70">
          <div className="row">
            <h3 className="text-center clr-red">
              شاهد الان كل الدورات التدربية لدينا
            </h3>
          </div>
          {/* <FilterComponent lang={"ar"} /> */}
          {course && (
            <>
              {course.courses.length == 0 ? (
                <>
                  <div className="row">
                    <h3 className="text-center clr-red">
                      لا يوجد لدينا كورسات في الوقت الحالي
                    </h3>
                  </div>
                </>
              ) : (
                <>
                  <div className="row align-items-center back-vertical-middle shorting__course2 mb-50">
                    <div className="col-md-6">
                      <div className="all__icons">
                        <div className="result-count">
                          وجدنا {course && course.totalCourses} كورسات جديدة
                          متاحة الآن للشراء
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {course.courses &&
                      course.courses.map((item, index) => {
                        return (
                          <CoursesCard
                            key={item._id}
                            id={item._id}
                            title={item.name.ar}
                            imgurl={item.image}
                            price={item.price}
                          />
                        );
                      })}
                    {/*
            <CoursesCard
              title={"الاحصائيات الفضائية والتحليل الكوني"}
              imgurl={coursefilter_01}
              price={"35 جنيها"}
              btnTitle={"شراء الان"}
            /> */}
                  </div>
                  {/* <ul className="back-pagination pt---20">
                <li>
                  <a href="#">1</a>
                </li>
                <li>
                  <a href="#">2</a>
                </li>
                <li className="back-next">
                  <a href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-arrow-right"
                    >
                      <line x1={5} y1={12} x2={19} y2={12} />
                      <polyline points="12 5 19 12 12 19" />
                    </svg>
                  </a>
                </li>
              </ul> */}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

function CoursePageGridEngish({ course }) {
  return (
    <>
      <div className="react-course-filter back__course__page_grid pt---140 pt---110">
        <div className="container pb---70">
          <div className="row">
            <h3 className="text-center clr-red">Watch All Of Our Courses </h3>
          </div>
          {/* <FilterComponent lang={"en"} /> */}
          {course && (
            <>
              {course.courses.length == 0 ? (
                <>
                  <div className="row">
                    <h3 className="text-center clr-red">
                      There is No Courses Added Yet.
                    </h3>
                  </div>
                </>
              ) : (
                <>
                  <div className="row align-items-center back-vertical-middle shorting__course2 mb-50">
                    <div className="col-md-6">
                      <div className="all__icons">
                        <div className="result-count">
                          we found {course && course.totalCourses} courses now
                          available for you
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {course.courses &&
                      course.courses.map((item, index) => {
                        return (
                          <CoursesCard
                            key={item._id}
                            id={item._id}
                            title={item.name.en}
                            imgurl={item.image}
                            price={item.price}
                          />
                        );
                      })}
                    {/* <CoursesCard
              tag={"Begginer"}
              title={"Progarming The Satalite In Space"}
              imgurl={coursefilter_01}
              price={"35 pound"}
              btnTitle={"Buy Now"}
            />

            <CoursesCard
              tag={"Begginer"}
              title={"Progarming The Satalite In Space"}
              imgurl={coursefilter_01}
              price={"35 pound"}
              btnTitle={"Buy Now"}
            />

            <CoursesCard
              tag={"Begginer"}
              title={"Progarming The Satalite In Space"}
              imgurl={coursefilter_01}
              price={"35 pound"}
              btnTitle={"Buy Now"}
            />

            <CoursesCard
              tag={"Begginer"}
              title={"Progarming The Satalite In Space"}
              imgurl={coursefilter_01}
              price={"35 pound"}
              btnTitle={"Buy Now"}
            />

            <CoursesCard
              tag={"Begginer"}
              title={"Progarming The Satalite In Space"}
              imgurl={coursefilter_01}
              price={"35 pound"}
              btnTitle={"Buy Now"}
            />

            <CoursesCard
              tag={"Begginer"}
              title={"Progarming The Satalite In Space"}
              imgurl={coursefilter_01}
              price={"35 pound"}
              btnTitle={"Buy Now"}
            />

            <CoursesCard
              tag={"Begginer"}
              title={"Progarming The Satalite In Space"}
              imgurl={coursefilter_01}
              price={"35 pound"}
              btnTitle={"Buy Now"}
            />

            <CoursesCard
              tag={"Begginer"}
              title={"Progarming The Satalite In Space"}
              imgurl={coursefilter_01}
              price={"35 pound"}
              btnTitle={"Buy Now"}
            />

            <CoursesCard
              tag={"Begginer"}
              title={"Progarming The Satalite In Space"}
              imgurl={coursefilter_01}
              price={"35 pound"}
              btnTitle={"Buy Now"}
            /> */}
                  </div>
                  {/* <ul className="back-pagination pt---20">
                <li className="back-next">
                  <a href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-arrow-left"
                    >
                      <line x1={5} y1={12} x2={19} y2={12} />
                      <polyline points="12 5 19 12 12 19" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="#">1</a>
                </li>
                <li>
                  <a href="#">2</a>
                </li>
              </ul> */}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
