import ForgetPassordForm from "./component/ForgetPassordForm";

import { useEffect } from "react";

export default function ForgetPassord() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
    return <>
  
          <ForgetPassordForm />
       
    </>
};
