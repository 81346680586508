import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/bootstrap.min.css";
import "./styles/menus.css";
import "./styles/animate.css";
// import "./styles/owl.carousel.css";
import "./styles/all.min.css";
import "./styles/swiper.min.css";
import "./styles/magnific-popup.css";
import "./styles/animations.css";
import "./styles/style.css";
import "./styles/variables.css";
import "./styles/custom-spacing.css";
import "./styles/responsive.css";
import "./styles/additions.css";

import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Singup from "./pages/Singup/Singup";
import Contact from "./pages/Contact/Contact";
import Courses from "./pages/Courses/Courses";
import About from "./pages/About/About";
import SingleCoursePage from "./pages/Courses/SingleCoursePage";
import Profile from "./pages/Profile/Profile";
import ShoppingCart from "./pages/ShoppingCart/ShoppingCart";
import Lessons from "./pages/Lessons/Lessons";
import Instractor from "./pages/Instractors/Instractor";
// import HeaderTop from "./component/HeaderTop";
import Wrapper from "./component/Wrapper";
import Footer from "./component/Footer";
import { Route, Routes } from "react-router";

import HeaderTop from "./component/HeaderTop";
import { useState } from "react";
import { useEffect } from "react";
import Error from "./pages/Error/Error";
import Payment from "./pages/Payment/Payment";
import InsctractorsProfile from "./pages/InstractorProfile/InsctractorsProfile";
import Policy from "./pages/About/Policy";
import Loading from "./component/ui/Loading";
import ForgetPassord from "./pages/Resets/ForgetPassord";
import ResetPassword from "./pages/Resets/ResetPassword";
function App() {
  const [loading, setLoading] = useState(true);
  const [islogin, setIsLoagin] = useState(false);
  const [date, setdata] = useState(null);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3500);
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  useEffect(() => {
    if (!date) {
      return;
    }
    const data = JSON.parse(localStorage.getItem("userData"));
    setdata(data);
    setIsLoagin(true);
  }, [date]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <HeaderTop />
      <Wrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/policy" element={<Policy />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/single/:id" element={<SingleCoursePage />} />
          <Route path="/instractor" element={<Instractor />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassord />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/singup" element={<Singup />} />
          <Route
            path="/instractor-profile/:inst_id"
            element={<InsctractorsProfile />}
          />
          <Route path="/profile" element={<Profile />} />
          {/* <Route path="/profile/:data" element={<Profile />} />  */}
          <Route path="/cart/:id" element={<ShoppingCart />} />
          <Route path="/payment/:id" element={<Payment />} />
          <Route path="/lesson/:id" element={<Lessons />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Wrapper>
      <Footer />
    </>
  );
}

export default App;
