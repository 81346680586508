import { createSlice } from "@reduxjs/toolkit";

const langSlicer = createSlice({
    name:'labguage',
    initialState:{
        lang :'ar'
    },
    reducers:{
        setToArabic: (state)=>{
            state.lang ='ar';
            document.getElementsByTagName('html')[0].setAttribute('dir','rtl')
            document.getElementsByTagName('html')[0].setAttribute('lang',state.lang)

        },
        setToEnglish: (state)=>{
            state.lang ='en' ;
            document.getElementsByTagName('html')[0].setAttribute('dir','ltr')
            document.getElementsByTagName('html')[0].setAttribute('lang',state.lang)
   
        }
    }
})

export const {setToArabic,setToEnglish} = langSlicer.actions; 
export default langSlicer.reducer;