import { useEffect, useState } from "react";
import { NavLink } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { CiDark } from "react-icons/ci";
import { LuLanguages } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { setToArabic, setToEnglish } from "../store/langSlicer";
import { Link } from "react-router-dom";
import { ChangeTheam } from "../store/TheamSlicer";

import logolight from "../Images/light.png";
import logoDark from "../Images/logoDark.png";
import logoGray from "../Images/logoGray.png";
import Avatar from "./Avatar";

export default function HeaderTop() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);
  const { them } = useSelector((state) => state.theam);
  const { user } = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const data = JSON.parse(localStorage.getItem("userData"));

  const [expand, setExpand] = useState(false);
  const handleExpand = () => {
    setExpand(!expand);
  };

  function HeaderArabic() {
    return (
      <>
        <Navbar collapseOnSelect expand="lg" className="top-header">
          <Container>
            <Navbar.Brand>
              <div className="logo">
                <Link className="logo-text" to={"/"} >
                  {them === "light" ? (
                    <>
                      <img src={logoGray} alt="logo" />
                    </>
                  ) : (
                    <>
                      <img src={logoDark} alt="logo" />
                    </>
                  )}
                </Link>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              type="button"
              id="menu-btn"
              onClick={handleExpand}
            >
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </Navbar.Toggle>
            <Navbar.Collapse
              className={`${expand && "show"}`}
              id="responsive-navbar-nav"
            >
              <Nav className="ms-auto  home-four gap-2">
                <Link
                  className="nav-link"
                  to={"/"}
                  onClick={handleExpand}
                >
                  الرئيسية
                </Link>
                <Link
                  className="nav-link"
                  to={"/courses"}
                  onClick={handleExpand}
                >
                  الكورسات
                </Link>
                <Link
                  className="nav-link"
                  to={"/about"}
                  onClick={handleExpand}
                >
                  من نحن
                </Link>
                <Link
                  className="nav-link"
                  to={"/contact"}
                  onClick={handleExpand}
                >
                  تواصل معنا
                </Link>
              </Nav>
              <Nav>
                <div className="searchbar-part">
                  <div className="custom-them-mode d-flex">
                    <button
                      className="btn"
                      onClick={() => {
                        dispatch(ChangeTheam());
                        handleExpand();
                      }}
                    >
                      <CiDark />
                    </button>

                    <button
                      className="btn"
                      onClick={() => {
                        if (lang === "en") {
                          dispatch(setToArabic());
                          setIsArLanguage(true);
                        } else {
                          dispatch(setToEnglish());
                          setIsArLanguage(false);
                        }
                        handleExpand();
                      }}
                    >
                      <LuLanguages />
                    </button>
                  </div>

                  {data != null ? (
                    <>
                      <Avatar handleExpand={handleExpand}/>
                    </>
                  ) : (
                    <>
                      <div className="react-login">
                        <Link className="btn bg-danger" to={"/login"} onClick={handleExpand}>
                          تسجيل
                        </Link>
                      </div>{" "}
                    </>
                  )}
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }

  function HeaderEnglish() {
    return (
      <>
        <Navbar collapseOnSelect expand="lg" className="top-header">
          <Container>
            <Navbar.Brand>
              <div className="logo">
                <Link className="logo-text" to={"/"} >
                  {them === "light" ? (
                    <>
                      <img src={logoGray} alt="logo" />
                    </>
                  ) : (
                    <>
                      <img src={logoDark} alt="logo" />
                    </>
                  )}
                </Link>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              type="button"
              id="menu-btn"
              onClick={handleExpand}
            >
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </Navbar.Toggle>

            <Navbar.Collapse
              className={`${expand && "show"}`}
              id="responsive-navbar-nav"
            >
              <Nav className="me-auto  home-four gap-2">
                <Link className="nav-link" to={"/"} onClick={handleExpand}>
                  Home{" "}
                </Link>
                <Link className="nav-link" to={"/courses"} onClick={handleExpand}>
                  Courses{" "}
                </Link>
                <Link className="nav-link" to={"/about"} onClick={handleExpand}>
                  About
                </Link>
                <Link className="nav-link" to={"/contact"} onClick={handleExpand}>
                  Contact
                </Link>
              </Nav>
              <Nav>
                <div className="searchbar-part">
                  <div className="custom-them-mode d-flex">
                    <button
                      className="btn"
                      onClick={() => {
                        dispatch(ChangeTheam());
                        handleExpand();
                      }}
                    >
                      <CiDark />
                    </button>

                    <button
                      className="btn"
                      onClick={() => {

                        if (lang === "en") {
                          dispatch(setToArabic());
                          setIsArLanguage(true);
                        } else {
                          dispatch(setToEnglish());
                          setIsArLanguage(false);
                        }
                        handleExpand();
                      }}
                    >
                      <LuLanguages />
                    </button>
                  </div>

                  {data != null ? (
                    <>
                      <Avatar handleExpand={handleExpand} />
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="react-login">
                        <Link className="btn bg-danger" to={"/login"} onClick={handleExpand}>
                          Login
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, []);
  return (
    <>
      <header
        id="react-header"
        className="react-header react-header-four-top-header"
      >
        <div className="menu-part">
          {isArlanguage ? <HeaderArabic /> : <HeaderEnglish />}
        </div>
      </header>
    </>
  );
}
