import Wrapper from "../../component/Wrapper";
import { useEffect } from "react";

import Header from "../../component/Header";
import Footer from "../../component/Footer";
import InsctractorsCardSection from "../Home/component/InsctractorsCardSection";
import HeaderTop from "../../component/HeaderTop";
import CoursePageGrid from "./component/CoursePageGrid";
import { Outlet } from "react-router-dom";
export default function Courses() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    
      <CoursePageGrid />
       
    </>
  );
}
