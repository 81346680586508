import SingUpForm from "./component/SingUpForm";
import HeaderTop from "../../component/HeaderTop";
import MinFooter from "../../component/MinFooter";
import Wrapper from "../../component/Wrapper";
import { useEffect } from "react";

export default function Singup() {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return <>
      
        <SingUpForm   />
        
    </>
};
