import ContactSection from "./component/ContactSection";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import HeaderTop from "../../component/HeaderTop";
import Wrapper from "../../component/Wrapper";
import InsctractorsCardSection from "../Home/component/InsctractorsCardSection";
import { useEffect } from "react";


export default function Contact() {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
        return <>
   
          
            <ContactSection />
       
        </>
};
