import Footer from "../../component/Footer";
import Header from "../../component/Header";
import HeaderTop from "../../component/HeaderTop";
import ProfileComponent from "./component/ProfileComponent";
import Wrapper from "../../component/Wrapper";
import { useEffect, useState } from "react";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function InsctractorsProfile() {
const {inst_id} = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
        <ProfileComponent inst_id={inst_id} />
    </>
  );
}
