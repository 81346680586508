import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const createUser = createAsyncThunk(
  "createUser",
  async (userCredintianals) => {
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/signup`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: userCredintianals.name,
          mobile: userCredintianals.mobile,
          title:"new student",
          email: userCredintianals.email,
          password: userCredintianals.password,
          role: "student",
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => error);

    const response = request;

    return response;
  }
);

const addUserSlicer = createSlice({
  name: "addUser",
  initialState: {
    loading: false,
    addUser: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(createUser.pending,(state)=>{
        state.loading = true;
        state.addUser = null;
        state.error = null

    });
    builder.addCase(createUser.fulfilled,(state,action)=>{
        if (action.payload.message === " User created Successfully") {
            state.loading = false;
            state.addUser = action.payload;
            state.error = null;
        } else {
            state.loading = false;
            state.addUser = null;
            state.error = action.payload.message;
        }

    });
    builder.addCase(createUser.rejected,(state,action)=>{
        state.loading = false;
        state.addUser = null;
        state.error = action.payload.message;

    });
  },
});

export default addUserSlicer.reducer;
