import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

//images

import logoDark from "../Images/logoDark.png";
import logoGray from "../Images/logoGray.png";

import FawryPay_Logo from "../Images/payment/FawryPay_Logo.png";
import paymobLogo from "../Images/payment/paymobLogo.png";
import paymentLogos from "../Images/payment/Partners-footer.webp";
import MinFooter from "./MinFooter";

export default function Footer() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);
  const { them } = useSelector((state) => state.theam);

  const { pathname } = useLocation();

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);
  function FooterArabic() {
    return (
      <>
        <footer id="react-footer" className="react-footer home-main">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 md-mb-30 ">
                  <div className="footer-widget footer-widget-1">
                    <div className="footer-logo white">
                      <Link to="/" className="logo-text">
                        {them === "light" ? (
                          <>
                            <img src={logoGray} alt="logo" />
                          </>
                        ) : (
                          <>
                            <img src={logoDark} alt="logo" />
                          </>
                        )}
                      </Link>
                    </div>
                    <h5 className="footer-subtitle">
                      Spacecavez هي اول منصة عربية لعلوم الفضاء فالشرق الأوسط
                      وشمال أفريقيا ، مهمتنا هي جعل علوم وتكنولوچيا الفضاء اكثر
                      اتاحة وتوافرا للجميع .
                    </h5>
                    <ul className="footer-address">
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-mail"
                        >
                          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                          <polyline points="22,6 12,13 2,6"></polyline>
                        </svg>
                        <a href="mailto:info@spacecavez.com">
                          {" "}
                          info@spacecavez.com{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 md-mb-30">
                  <div className="footer-widget footer-widget-2">
                    <h3 className="footer-title">اعرف عنا </h3>
                    <div className="footer-menu">
                      <ul>
                        <li>
                          <Link to="/about">من نحن</Link>
                        </li>
                        <li>
                          <Link to="/courses">الكورسات</Link>
                        </li>
                        <li>
                          <Link to={"/instractor"}>انضم الي فريقنا</Link>
                        </li>
                        <li>
                          <Link to={"/contact"}>تواصل معنا</Link>
                        </li>
                        <li>
                          <Link to={"/about/policy"}>سياسة الخصوصية</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3 md-mb-30">
                  <div className="footer-widget footer-widget-3">
                    <h3 className="footer-title">وسائل الدفع</h3>
                    <div className="footer-menu">
                      <ul>
                        <li>
                          <img src={paymobLogo} width={100} />
                        </li>
                        <li className="py-2">
                          <img src={FawryPay_Logo} width={100} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-4 md-mb-30 ">
                  <div className="footer-widget footer-widget-4">
                    <h3 className="footer-title">أخبارنا علي مدار الساعة</h3>
                    <div className="footer3__form">
                      <p>انضم الينا واعرف الاخبار عنا علي مدار الساعة</p>
                      <form action="#">
                        <input
                          type="email"
                          placeholder="ادخل البريد الاكتروني"
                        />
                        <button className="footer3__form-1">
                          <i className="fa-regular fa-paper-plane"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="custom-payment-logos">
            <div className="container">
              <img src={paymentLogos} />
            </div>
          </div>

          <div className="copyright">
            <div className="container">
              <div className="react-copy-left">
                © 2023 <a href="#">SPACECAVEZ</a> كل الحقوق محفوظة لدي شركتنا
              </div>
              <div className="react-copy-right">
                <ul className="social-links">
                  <li className="follow">تابعنا علي</li>
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=100090626162515"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/spacecavez/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/spacecavez1/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
  function FooterEnglish() {
    return (
      <>
        <footer id="react-footer" className="react-footer home-main">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 md-mb-30">
                  <div className="footer-widget footer-widget-1">
                    <div className="footer-logo white">
                      <Link to="/" className="logo-text">
                        {them === "light" ? (
                          <>
                            <img src={logoGray} alt="logo" />
                          </>
                        ) : (
                          <>
                            <img src={logoDark} alt="logo" />
                          </>
                        )}
                      </Link>
                    </div>
                    <h5 className="footer-subtitle">
                      Spacecavez is the first Arabic space education platform in
                      MENA region, dedicated to making space education more
                      accessible and affordable for everyone.
                    </h5>
                    <ul className="footer-address">
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-mail"
                        >
                          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                          <polyline points="22,6 12,13 2,6"></polyline>
                        </svg>
                        <a href="mailto:info@spacecavez.com">
                          {" "}
                          info@spacecavez.com{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 md-mb-30">
                  <div className="footer-widget footer-widget-2">
                    <h3 className="footer-title">Anout Us </h3>
                    <div className="footer-menu">
                      <ul>
                        <li>
                        <Link to="/about">About</Link>
                        </li>
                        <li>
                          <Link to="/courses">Courses</Link>
                        </li>
                        <li>
                          <Link to={"/instractor"} href="#">
                            Become Instractor
                          </Link>
                        </li>
                        <li>
                          <Link to={"/contact"}>Contact Us</Link>
                        </li>
                        <li>
                          <Link to={"/about/policy"}>Privacy Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-3 md-mb-30">
                  <div className="footer-widget footer-widget-3">
                    <h3 className="footer-title">Payment Method</h3>
                    <div className="footer-menu">
                      <ul>
                        <li>
                          <img src={paymobLogo} width={100} />
                        </li>
                        <li className="py-2">
                          <img src={FawryPay_Logo} width={100} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-4 md-mb-30 ">
                  <div className="footer-widget footer-widget-4">
                    <h3 className="footer-title">Newslister</h3>
                    <div className="footer3__form">
                      <p> Join Us To Know More</p>
                      <form action="#">
                        <input type="email" placeholder="Email Address" />
                        <button className="footer3__form-1">
                          <i className="fa-regular fa-paper-plane"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="custom-payment-logos">
            <div className="container">
              <img src={paymentLogos} />
            </div>
          </div>
          <div className="copyright">
            <div className="container">
              <div className="react-copy-left">
                © 2023 <a href="#">SPACECAVEZ.</a> All Rights Reserved
              </div>
              <div className="react-copy-right">
                <ul className="social-links">
                  <li className="follow">Follow Us</li>
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=100090626162515"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/spacecavez/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/spacecavez1/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }

  return (
    <>
      {pathname.includes("lesson") ? (
        <MinFooter />
      ) : (
        <>{lang === "ar" ? <FooterArabic /> : <FooterEnglish />}</>
      )}
    </>
  );
}
