import { useEffect } from "react";
import AboutArea from "./component/AboutArea";

export default function About() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
  
       <AboutArea />
     
    </>
  );
}
