import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  Autoplay,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// images
import instructor_01 from "../../../Images/instructor/1.png";
import instructor_02 from "../../../Images/instructor/2.png";
import instructor_03 from "../../../Images/instructor/3.png";
import instructor_04 from "../../../Images/instructor/4.png";
import instructor_05 from "../../../Images/instructor/5.png";
import instructor_06 from "../../../Images/instructor/6.png";
import { getInstractorsApi } from "../../../store/getAllInstractorsSlicer";
import Loader from "../../../component/Loader";
import { Link } from "react-router-dom";
import IMG from "../../../component/ui/IMG";
import avatarMain from "../../../Images/avatar/avatar-main.png";

const instrctorArr = [
  {
    name: "ميار وائل",
    img: instructor_01,
    subtitle: "مهندس متخصص",
  },
  {
    name: "محمود حمدي",
    img: instructor_02,
    subtitle: "مهندس متخصص",
  },
  {
    name: "باهر ايمن",
    img: instructor_03,
    subtitle: "مهندس متخصص",
  },
  {
    name: "هايا علاء",
    img: instructor_04,
    subtitle: "مهندس متخصص",
  },
  {
    name: "سيد لاشين ",
    img: instructor_05,
    subtitle: "مهندس متخصص",
  },
  {
    name: "محمد فوزي ",
    img: instructor_06,
    subtitle: "مهندس متخصص",
  },
];
const instrctorArren = [
  {
    name: "Mayar Wael",
    img: instructor_01,
    subtitle: "Instructor",
  },
  {
    name: "Mohammed Hamdy",
    img: instructor_02,
    subtitle: "Instructor",
  },
  {
    name: "Baher Aymen",
    img: instructor_03,
    subtitle: "Instructor",
  },
  {
    name: "Haya Alaa",
    img: instructor_04,
    subtitle: "Instructor",
  },
  {
    name: "Sayed Lashin",
    img: instructor_05,
    subtitle: "Instructor",
  },
  {
    name: "Mohamed Fawzy ",
    img: instructor_06,
    subtitle: "Instructor",
  },
];

export default function InsctractorsCardSection() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);
  const { loading, error } = useSelector((state) => state.getInstractors);
  const dispatch = useDispatch();

  const [instractors, setInstractors] = useState();

  useEffect(() => {
    dispatch(getInstractorsApi()).then((resulet) => {
      setInstractors(resulet.payload.data);
    });
  }, []);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);
  return (
    <>
      {loading ? (
        <div className="react-course-filter back__course__page_grid pt---140 pt---110">
          <div className="container pb---70">
            <div className="row">
              <div className="col-12 text-center">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {error ? (
            <>
              <div className="react-course-filter back__course__page_grid pt---140 pt---110">
                <div className="container pb---70">
                  <div className="row">
                    <div className="col-12 text-center">
                      <h2>Some Error Happen </h2>
                      <Link className="btn btn-danger" to={"/"}>
                        Home
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {instractors && (
                <>
                  {isArlanguage ? (
                    <InsctractorsCardArabic
                      instractors={instractors.teachers}
                    />
                  ) : (
                    <InsctractorsCardEnglish
                      instractors={instractors.teachers}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

function InsctractorsCardArabic({ instractors }) {
  return (
    <>
      <div className="instructor__area pt---50 pb---110 text-center">
        <div className="container">
          <div className="row">
            <div className="col">
              {instractors.length == 0 ? (
                <>
                  <h3>سوف يتم اضافة المعلمين قريبا !</h3>
                </>
              ) : (
                <>
                  <Swiper
                    modules={[
                      Navigation,
                      Pagination,
                      Scrollbar,
                      A11y,
                      Autoplay,
                    ]}
                    spaceBetween={50}
                    slidesPerView={1}
                    rewind
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: true,
                    }}
                    navigation
                    // Responsive breakpoints
                    breakpoints={{
                      300: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                        draggable: true,
                      },
                      // when window width is >= 480px
                      350: {
                        slidesPerView: 1.5,
                        spaceBetween: 20,
                      },
                      // when window width is >= 640px
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                      1000: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                      },
                    }}
                  >
                    {instractors.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="instructor__content">
                            <div className="instructor__content-1">
                              {item.image ? (
                                <IMG src={item.image} title={item.name} />
                              ) : (
                                <img src={avatarMain} alt={item.name} />
                              )}
                              {/* <img src={item.img} alt={item.name} /> */}
                            </div>
                            <div className="instructor__content-2">
                              <h4>
                                <Link to={`/instractor-profile/${item._id}`}>
                                  {item.name}
                                </Link>
                              </h4>
                              {item.title && (
                                <>
                                  <p>{item.title}</p>
                                </>
                              )}
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function InsctractorsCardEnglish({ instractors }) {
  return (
    <>
      <div className="instructor__area pt---50 pb---110 text-center">
        <div className="container">
          <div className="row">
            <div className="col">
              {instractors.length == 0 ? (
                <>
                  <h3>We Will Add Instractors Soon !</h3>
                </>
              ) : (
                <>
                  <Swiper
                    modules={[
                      Navigation,
                      Pagination,
                      Scrollbar,
                      A11y,
                      Autoplay,
                    ]}
                    spaceBetween={50}
                    slidesPerView={1}
                    rewind
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: true,
                    }}
                    navigation
                    // Responsive breakpoints
                    breakpoints={{
                      300: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                        draggable: true,
                      },
                      // when window width is >= 480px
                      350: {
                        slidesPerView: 1.5,
                        spaceBetween: 20,
                      },
                      // when window width is >= 640px
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                      1000: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                      },
                    }}
                  >
                    {instractors.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="instructor__content">
                            <div className="instructor__content-1">
                              {item.image ? (
                                <IMG src={item.image} title={item.name} />
                              ) : (
                                <img src={avatarMain} alt={item.name} />
                              )}
                              {/* <img src={item.img} alt={item.name} /> */}
                            </div>
                            <div className="instructor__content-2">
                              <h4>
                                <Link to={`/instractor-profile/${item._id}`}>
                                  {item.name}
                                </Link>
                              </h4>
                              {item.title && (
                                <>
                                  <p>{item.title}</p>
                                </>
                              )}
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                    {/* <SwiperSlide>
                  <div className="instructor__content">
                    <div className="instructor__content-1">
                      <img
                        src={instructor_03}
                        alt="course instructor picture"
                      />
                    </div>
                    <div className="instructor__content-2">
                      <h4>
                        <a href="profile.html">Jason Response</a>
                      </h4>
                      <p>Education Assistant</p>
                    </div>
                  </div>
                </SwiperSlide> */}
                  </Swiper>
                </>
              )}
            </div>
            {/* <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="instructor__content">
                <div className="instructor__content-1">
                  <img src={instructor_03} alt="course instructor picture" />
                </div>
                <div className="instructor__content-2">
                  <h4>
                    <a href="profile.html">Jason Response</a>
                  </h4>
                  <p>Education Assistant</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="instructor__content">
                <div className="instructor__content-1">
                  <img src="assets/images/instructor/04.jpg" alt="course instructor picture" />
                </div>
                <div className="instructor__content-2">
                  <h4>
                    <a href="profile.html">Jonquil Von</a>
                  </h4>
                  <p>Teaching Assistant</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="instructor__content">
                <div className="instructor__content-1">
                  <img src="assets/images/instructor/05.jpg" alt="course instructor picture" />
                </div>
                <div className="instructor__content-2">
                  <h4>
                    <a href="profile.html">Piff Jenkins</a>
                  </h4>
                  <p>Teacher</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="instructor__content">
                <div className="instructor__content-1">
                  <img src="assets/images/instructor/06.jpg" alt="course instructor picture" />
                </div>
                <div className="instructor__content-2">
                  <h4>
                    <a href="profile.html">Brian Cumin</a>
                  </h4>
                  <p>Lead Teacher</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="instructor__content">
                <div className="instructor__content-1">
                  <img src="assets/images/instructor/07.jpg" alt="course instructor picture" />
                </div>
                <div className="instructor__content-2">
                  <h4>
                    <a href="profile.html">Hanson Deck</a>
                  </h4>
                  <p>Teacher</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="instructor__content">
                <div className="instructor__content-1">
                  <img src="assets/images/instructor/08.jpg" alt="course instructor picture" />
                </div>
                <div className="instructor__content-2">
                  <h4>
                    <a href="profile.html">Alan Fresco</a>
                  </h4>
                  <p>Teacher</p>
                </div>
              </div>
            </div>  */}
          </div>
        </div>
      </div>
    </>
  );
}
