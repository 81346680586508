import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//image
import avatarMain from "../../../Images/avatar/avatar-main.png";
import profile_01 from "../../../Images/profile/1.jpg";
import coursefilter_01 from "../../../Images/course-filter/1.jpg";
import { getSingleInstractorsDataApi } from "../../../store/getInstractorByIdSlicer";
import { Link } from "react-router-dom";
import Loader from "../../../component/Loader";
import IMG from "../../../component/ui/IMG";
import ErrorStatment from "../../../component/ui/ErrorStatment";

export default function ProfileComponent({ inst_id }) {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);
  const { loading, date, error } = useSelector(
    (state) => state.getInstractorById
  );
  const dispatch = useDispatch();

  const [instractorData, setInstractorData] = useState();

  useEffect(() => {
    dispatch(getSingleInstractorsDataApi(inst_id)).then((resulet) => {
      setInstractorData(resulet.payload.data);
      console.log(instractorData);
    });
  }, []);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);

  return (
    <>
      {loading ? (
        <div className="react-course-filter back__course__page_grid pt---140 pt---110">
          <div className="container pb---70">
            <div className="row">
              <div className="col-12 text-center">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {error ? (
            <>
              <ErrorStatment />
            </>
          ) : (
            <>
              {instractorData && (
                <>
                  {isArlanguage ? (
                    <ProfileComponentArabic instractorData={instractorData} />
                  ) : (
                    <ProfileComponentEnglish instractorData={instractorData} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

function ProfileComponentArabic({ instractorData }) {
  return (
    <>
      <div className="profile-top back__course__area pt---120 pb---90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              {/* <img src={userData.img} alt={userData.name} /> */}
              {instractorData.image ? (
                <IMG src={instractorData.image} title={instractorData.name} />
              ) : (
                <img src={avatarMain} alt={instractorData.name} />
              )}
            </div>
            <div className="col-lg-8">
              {/* <UserProfile userData={userProfile.userData} /> */}

              <ul className="user-section">
                <li className="user">
                  <span className="name">الاسم:</span>
                  <b> {instractorData.name}</b>
                </li>
                <li>
                  اللقب :<b> {instractorData.title} </b>
                </li>
                <li>
                  تلفون:<b> {instractorData.mobile}</b>
                </li>
                <li>
                  بريد الكتروني :<b> {instractorData.email}</b>
                </li>
              </ul>
              {instractorData.description && (
                <>
                  <h3 className="py-2">السيرة الزاتية </h3>
                  <p>{instractorData.description}</p>
                </>
              )}

              <div className="count__area2">
                <ul className="row"></ul>
              </div>
              <h2 className="teacher__course pt-3">الكورسات الخاصة </h2>
              <div className="react-course-filter related__course">
                <div className="row react-grid">
                  {instractorData.courses &&
                  instractorData.courses.length !== 0 ? (
                    <>
                      {instractorData.courses.map((course, index) => (
                        <div
                          key={index}
                          className="single-studies col-lg-6 grid-item"
                        >
                          <div className="inner-course">
                            <div className="case-img">
                              {course.image && course.image != "" ? (
                                <IMG
                                  src={course.image}
                                  title={course.name.en}
                                />
                              ) : (
                                <img src={coursefilter_01} alt="Course Image" />
                              )}
                            </div>
                            <div className="case-content">
                              <h4 className="case-title">
                                <Link to={`/single/${course._id}`}>
                                  {course.name.ar}
                                </Link>
                              </h4>

                              <ul className="react-ratings">
                                <li className="price">
                                  {course.price / 100} جنيه{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <h4>لا يوجد كورسات في الوقت الحالي </h4>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ProfileComponentEnglish({ instractorData }) {
  return (
    <>
      <div className="profile-top back__course__area pt---120 pb---90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              {/* <img src={userData.img} alt={userData.name} /> */}

              {instractorData.image ? (
                <IMG src={instractorData.image} title={instractorData.name} />
              ) : (
                <img src={avatarMain} alt={instractorData.name} />
              )}
            </div>
            <div className="col-lg-8">
              {/* <UserProfile userData={userProfile.userData} /> */}

              <ul className="user-section">
                <li className="user">
                  <span className="name">Name: </span>
                  <b>{instractorData.name}</b>
                </li>
                <li>
                  Title:<b> {instractorData.title}</b>{" "}
                </li>
                <li>
                  Phone: <b> {instractorData.mobile}</b>{" "}
                </li>
                <li>
                  Email: <b> {instractorData.email} </b>{" "}
                </li>
              </ul>
              {instractorData.description && (
                <>
                  <h3 className="py-2">Biography</h3>
                  <p>{instractorData.description}</p>
                </>
              )}

              <div className="count__area2">
                <ul className="row">
                  {/* {numbersAndStatic.map(item =>  <CardCountArea key={item.id}  countData={item} />)} */}

                  {/* <li className="col-lg-4">
                    <div className="count__content">
                      <div className="icon">
                        <img src="assets/images/profile/2.png" alt="profile" />
                      </div>
                      <div className="text">
                        <span className="count__content-title counter">28</span><em>k</em>
                        <p>Foreign followers</p> 
                      </div>                                           
                    </div>
                  </li> 
                  <li className="col-lg-4">
                    <div className="count__content">
                      <div className="icon">
                        <img src="assets/images/profile/3.png" alt="profile" />
                      </div>
                      <div className="text">
                        <span className="count__content-title counter">10</span><em>k</em>
                        <p>Classes complete</p> 
                      </div>                                           
                    </div>
                  </li>
                  <li className="col-lg-4">
                    <div className="count__content">
                      <div className="icon">
                        <img src="assets/images/profile/4.png" alt="profile" />
                      </div>
                      <div className="text">
                        <span className="count__content-title counter">208</span><em>k</em>
                        <p> Students enrolled</p>  
                      </div>                                          
                    </div>
                  </li>
                  */}
                </ul>
              </div>
              <h2 className="teacher__course pt-3">Teacher Courses</h2>
              <div className="react-course-filter related__course">
                <div className="row react-grid">
                  {instractorData.courses &&
                  instractorData.courses.length !== 0 ? (
                    <>
                      {instractorData.courses.map((course, index) => (
                        <div
                          key={index}
                          className="single-studies col-lg-6 grid-item"
                        >
                          <div className="inner-course">
                            <div className="case-img">
                              {course.image && course.image != "" ? (
                                <IMG
                                  src={course.image}
                                  title={course.name.en}
                                />
                              ) : (
                                <img src={coursefilter_01} alt="Course Image" />
                              )}
                            </div>
                            <div className="case-content">
                              <h4 className="case-title">
                                <Link to={`/single/${course._id}`}>
                                  {course.name.en}
                                </Link>
                              </h4>

                              <ul className="react-ratings">
                                <li className="price">
                                  {course.price / 100} EG{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <h4>No Courses Add Yet </h4>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
