import SingleCourseComponent from "./component/SingleCourseComponent";
import Footer from "../../component/Footer";
import HeaderTop from "../../component/HeaderTop";
import Wrapper from "../../component/Wrapper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCourse } from "../../store/getCourseByIdSlicer";
import Loader from "../../component/Loader";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ErrorStatment from "../../component/ui/ErrorStatment";

export default function SingleCoursePage() {
  const { loading, data, error } = useSelector((state) => state.getCourseById);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [courseData, setCourseData] = useState();

  useEffect(() => {
    dispatch(getCourse(id)).then((result) => {
        setCourseData(result.payload.data);
      
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
       {loading ? (
        <div className="react-course-filter back__course__page_grid pt---140 pt---110">
          <div className="container pb---70">
            <div className="row">
              <div className="col-12 text-center">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {error ? (
            <>
           
             <ErrorStatment />
           
            </>
          ) : (
            <>
              {courseData && (
                
                <SingleCourseComponent  data={courseData}/>
              )}
            </>
          )}
        </>
      )}
      
      
    </>
  );
}
