import { useEffect, useState } from "react";
import { Alert, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createUser } from "../../../store/addedUserSlicer";
import Loader from "../../../component/Loader";
import PolicyArea from "../../About/component/PolicyArea";

export default function SingUpForm() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);
 
  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);
  function SingUpFormArabic() {
    const [showModel, setShowModel] = useState(false);
    const [ischecked, setIschecked] = useState(false);
  
    const handleShow = () => setShowModel(true);
    const handleHide = () => setShowModel(false);
    // Stroe Data
    const { loading, addUser, error } = useSelector(
      (state) => state.createUser
    );
    const dispatch = useDispatch();
    const navegator = useNavigate();

    //form data states
    const [fullName, setFullName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    // form data error  message
    const [fullNameError, setFullNameError] = useState("");
    const [userEmailError, setUserEmailError] = useState("");
    const [userPasswordError, setUserPasswordError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");

    const handleSubmite = (e) => {
      e.preventDefault();
      if (
        fullNameError === "" &&
        userEmailError === "" &&
        userPasswordError === "" &&
        phoneNumberError === ""
      ) {
        const userCredintianals = {
          name: fullName,
          mobile: phoneNumber,
          email: userEmail,
          password: userPassword,
        };
        dispatch(createUser(userCredintianals)).then((result) => {
          if (result.payload.message === " User created Successfully") {
            setFullName("");
            setUserEmail("");
            setUserPassword("");
            setPhoneNumber("");
            navegator("/login");
          }
        });
      }
    };

    return (
      <>
        <div className="react-login-page react-signup-page pt---150 pb---120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="login-right-form">
                  <form onSubmit={handleSubmite}>
                    <div className="login-top">
                      <h3>أنشئ حساب جديد مجانا</h3>
                      لدي حساب بالفعل ؟<Link to={"/login"}> تسجيل الدخول </Link>
                    </div>
                    <p>
                      <label>ادخل الاسم بالكامل</label>
                      <input
                        requiredg
                        placeholder="ادخل الاسم بالكامل"
                        type="text"
                        id="name"
                        name="name"
                        onChange={(e) => {
                          if (e.target.value.length < 3) {
                            setFullNameError("يجب ان لا يقل الاسم عن 3 احرف");
                          } else {
                            setFullNameError("");
                          }
                          setFullName(e.target.value);
                        }}
                        value={fullName}
                      />
                    </p>
                    {fullNameError && (
                      <Alert variant="danger">{fullNameError}</Alert>
                    )}
                    <p>
                      <label>البريد الالكتروني</label>
                      <input
                        placeholder="البريد الالكتروني"
                        type="email"
                        id="email"
                        name="email"
                        onChange={(e) => {
                          if (e.target.value.length < 10) {
                            setUserEmailError("بريد الكتروني غير صالح");
                          } else {
                            setUserEmailError("");
                          }
                          setUserEmail(e.target.value);
                        }}
                        value={userEmail}
                      />
                    </p>
                    {userEmailError && (
                      <Alert variant="danger">{userEmailError}</Alert>
                    )}
                    <p>
                      <label>كلمة المرور</label>
                      <input
                        placeholder="كلمة المرور"
                        type="password"
                        id="pass"
                        name="pass"
                        onChange={(e) => {
                          if (e.target.value.length < 8) {
                            setUserPasswordError(
                              "كلمة المرور لا يجب ان تكون اقل من 8 احرف"
                            );
                          } else {
                            setUserPasswordError("");
                          }
                          setUserPassword(e.target.value);
                        }}
                        value={userPassword}
                      />
                    </p>
                    {userPasswordError && (
                      <Alert variant="danger">{userPasswordError}</Alert>
                    )}
                    <p>
                      <label>ادخل رقم الهاتف </label>
                      <input
                        style={{ direction: "rtl" }}
                        placeholder="رقم الهاتف"
                        type="tel"
                        name="phone"
                        maxLength={11}
                        onChange={(e) => {
                          if (
                            e.target.value[0] !== "0" &&
                            e.target.value[1] !== "1"
                          ) {
                            setPhoneNumberError(
                              "رقم هاتف غير صالح يجب ان يبدا ف 01xxxxxxxxx"
                            );
                          } else if (e.target.value.length != 11) {
                            setPhoneNumberError("القيمة يجب ان تكون 11 رقم");
                          } else {
                            setPhoneNumberError("");
                          }
                          setPhoneNumber(e.target.value);
                        }}
                        value={phoneNumber}
                      />
                    </p>
                    {phoneNumberError && (
                      <Alert variant="danger">{phoneNumberError}</Alert>
                    )}
                    <div className="back-check-box">
                      <input
                        type="checkbox"
                        id="box-1"
                        value={ischecked}
                        onChange={(e) => {
                          setIschecked(e.target.checked);
                        }}
                      />
                      الموافقة علي{" "}
                      <em onClick={handleShow} style={{ cursor: "pointer" }}>
                        {" "}
                        الشروط والاحكام{" "}
                      </em>
                      <p />
                    </div>
                    {addUser && (
                      <div className="col">
                        <div className="alert alert-success" role="alert">
                          {addUser.message}
                        </div>
                      </div>
                    )}
                    {error && (
                      <div className="col">
                        <div className="alert alert-danger" role="alert">
                          {error}
                        </div>
                      </div>
                    )}

                    {loading ? (
                      <>
                        <button
                          type="submit"
                          id="button"
                          name="submit"
                          disabled
                        >
                          <Loader />
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          id="button"
                          name="submit"
                          disabled={!ischecked}
                        >
                          تسجيل
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right"
                          >
                            <line x1={5} y1={12} x2={19} y2={12} />
                            <polyline points="12 5 19 12 12 19" />
                          </svg>
                        </button>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showModel}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body
            style={{
              height: "60vh",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
            <PolicyArea />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleHide}>اغلاق</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  function SingUpFormEnglish() {
    const [showModel, setShowModel] = useState(false);
    const [ischecked, setIschecked] = useState(false);
  
    const handleShow = () => setShowModel(true);
    const handleHide = () => setShowModel(false);
    // Stroe Data
    const { loading, addUser, error } = useSelector(
      (state) => state.createUser
    );
    const dispatch = useDispatch();
    const navegator = useNavigate();

    //form data states
    const [fullName, setFullName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    // form data error  message
    const [fullNameError, setFullNameError] = useState("");
    const [userEmailError, setUserEmailError] = useState("");
    const [userPasswordError, setUserPasswordError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");

    const handleSubmite = (e) => {
      e.preventDefault();
      if (
        fullNameError === "" &&
        userEmailError === "" &&
        userPasswordError === "" &&
        phoneNumberError === ""
      ) {
        const userCredintianals = {
          name: fullName,
          mobile: phoneNumber,
          email: userEmail,
          password: userPassword,
        };
        dispatch(createUser(userCredintianals)).then((result) => {
          if (result.payload.message === " User created Successfully") {
            setFullName("");
            setUserEmail("");
            setUserPassword("");
            setPhoneNumber("");
            navegator("/login");
          }
        });
      }
    };

    return (
      <>
        <div className="react-login-page react-signup-page pt---150 pb---120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="login-right-form">
                  <form onSubmit={handleSubmite}>
                    <div className="login-top">
                      <h3>Create Free Account</h3>
                      Already Have an Account ?{" "}
                      <Link to={"/login"}> Login </Link>
                    </div>
                    <p>
                      <label>Full Name</label>{" "}
                      <input
                        required
                        placeholder="Full Name"
                        type="text"
                        id="name"
                        name="name"
                        onChange={(e) => {
                          if (e.target.value.length < 3) {
                            setFullNameError("Min Charcters is 3 ");
                          } else {
                            setFullNameError("");
                          }
                          setFullName(e.target.value);
                        }}
                        value={fullName}
                      />
                    </p>
                    {fullNameError && (
                      <Alert variant="danger">{fullNameError}</Alert>
                    )}
                    <p>
                      <label>Email</label>
                      <input
                      required
                        placeholder="Email"
                        type="email"
                        id="email"
                        name="email"
                        onChange={(e) => {
                          if (e.target.value.length < 10) {
                            setUserEmailError(
                              "Invalied Email lenght, Min 10 Characters"
                            );
                          } else {
                            setUserEmailError("");
                          }
                          setUserEmail(e.target.value);
                        }}
                        value={userEmail}
                      />
                    </p>
                    {userEmailError && (
                      <Alert variant="danger">{userEmailError}</Alert>
                    )}
                    <p>
                      <label>Password</label>
                      <input
                      required
                        placeholder="Password"
                        type="password"
                        id="pass"
                        name="pass"
                        onChange={(e) => {
                          if (e.target.value.length < 8) {
                            setUserPasswordError(
                              "Password Should Not Be Less Than 8 Characters"
                            );
                          } else {
                            setUserPasswordError("");
                          }
                          setUserPassword(e.target.value);
                        }}
                        value={userPassword}
                      />
                    </p>
                    {userPasswordError && (
                      <Alert variant="danger">{userPasswordError}</Alert>
                    )}
                    <p>
                      <label>Phone Number </label>
                      <input
                      required
                        placeholder="Phone Number"
                        type="tel"
                        name="phone"
                        maxLength={11}
                        onChange={(e) => {
                          if (
                            e.target.value[0] !== "0" &&
                            e.target.value[1] !== "1"
                          ) {
                            setPhoneNumberError(
                              "Invaild Egyption Number Formate, The Valied Formate Is (01xxxxxxxxx)"
                            );
                          } else if (e.target.value.length != 11) {
                            setPhoneNumberError("Value lenght Is 11 Digite ");
                          } else {
                            setPhoneNumberError("");
                          }
                          setPhoneNumber(e.target.value);
                        }}
                        value={phoneNumber}
                      />
                    </p>
                    {phoneNumberError && (
                      <Alert variant="danger">{phoneNumberError}</Alert>
                    )}
                    <div className="back-check-box">
                      <input
                        type="checkbox"
                        id="box-1"
                        value={ischecked}
                        onChange={(e) => {
                          setIschecked(e.target.checked);
                        }}
                      />
                      Agree to
                      <em onClick={handleShow} style={{ cursor: "pointer" }}>
                     
                        Tearms and Condtions
                      </em>
                      <p />
                    </div>
                    {addUser && (
                      <div className="col">
                        <div className="alert alert-success" role="alert">
                          {addUser.message}
                        </div>
                      </div>
                    )}
                    {error && (
                      <div className="col">
                        <div className="alert alert-danger" role="alert">
                          {error}
                        </div>
                      </div>
                    )}

                    {loading ? (
                      <>
                        <button
                          type="submit"
                          id="button"
                          name="submit"
                          disabled
                        >
                          <Loader />
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          id="button"
                          name="submit"
                          disabled={!ischecked}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right"
                          >
                            <line x1={5} y1={12} x2={19} y2={12} />
                            <polyline points="12 5 19 12 12 19" />
                          </svg>
                          Sign up{" "}
                        </button>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showModel}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body
            style={{
              height: "60vh",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
            <PolicyArea />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return <>{isArlanguage ? <SingUpFormArabic /> : <SingUpFormEnglish />}</>;
}
