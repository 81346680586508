import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

// Import Swiper styles
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//images
import course_01 from "../../../Images/course/AIT1.png";
import course_02 from "../../../Images/course/orbital1.png";
import course_03 from "../../../Images/course/outreach1.png";
import course_04 from "../../../Images/course/propulsion1.png";
import course_05 from "../../../Images/course/REMOTE-SENSING1.png";
import course_06 from "../../../Images/course/Satellite-Design1.png";
import { getCoursesFromApi } from "../../../store/getAllCoursesSlicer";
import Loader from "../../../component/Loader";
import CoursesCard from "../../Courses/component/CoursesCard";
import NewCourseCard from "../../../component/sub-components/NewCourseCard";

const coursesArr = [
  {
    title: "مقدمة في علم الفضاء",
    img: course_01,
    price: 800,
  },
  {
    title: "المدارات الاساسية والفرعية للفضاء",
    img: course_02,
    price: 750,
  },
  {
    title: "الاجرات الاحترازية والتوعية",
    img: course_03,
    price: 400,
  },
  {
    title: "طاقة الدفع والتحريك في القمر الاصطناعي",
    img: course_04,
    price: 200,
  },
  {
    title: "التحكم عن بعد في البوابات الفضائية",
    img: course_05,
    price: 500,
  },
  {
    title: "تصميم الاقمار الاصطناعية ",
    img: course_06,
    price: 300,
  },
];
const coursesArren = [
  {
    title: "Introduction to space science",
    img: course_01,
    price: 800,
  },
  {
    title: "The basic and sub-orbits of space",
    img: course_02,
    price: 750,
  },
  {
    title: "Precautionary measures and awareness",
    img: course_03,
    price: 400,
  },
  {
    title: "Satellite propulsion and movement energy",
    img: course_04,
    price: 200,
  },
  {
    title: "Remote control of satellite gates",
    img: course_05,
    price: 500,
  },
  {
    title: "Artificial satellite design ",
    img: course_06,
    price: 300,
  },
];

export default function CoursesCardSection() {
  const [isArlanguage, setIsArLanguage] = useState(true);
  const { lang } = useSelector((state) => state.lang);
  const { loading, date, error } = useSelector((state) => state.getCourses);
  const dispatch = useDispatch();

  const [course, setCourse] = useState();

  useEffect(() => {
    dispatch(getCoursesFromApi("1")).then((resulet) => {
      setCourse(resulet.payload.data);
      console.log(resulet.payload.data);
    });
  }, []);

  useEffect(() => {
    if (lang === "ar") {
      setIsArLanguage(true);
    } else {
      setIsArLanguage(false);
    }
  }, [lang]);

  return (
    <>
      {loading ? (
        <div className="react-course-filter back__course__page_grid pt---140 pt---110">
          <div className="container pb---70">
            <div className="row">
              <div className="col-12 text-center">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {error ? (
            <>
              <div className="react-course-filter back__course__page_grid pt---140 pt---110">
                <div className="container pb---70">
                  <div className="row">
                    <div className="col-12 text-center">
                      <h2>Some Error Happen </h2>
                      <Link className="btn btn-danger" to={"/"}>
                        Home
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {course && (
                <>
                  {isArlanguage ? (
                    <CoursesCardArabic course={course} />
                  ) : (
                    <CoursesCardEnglish course={course} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

function CoursesCardArabic({ course }) {
  return (
    <>
      <div className="popular__course__area pt---50 pb---50 ">
        <div className="container">
          {/* <div className="react__title__section text-center">
            <h2 className="react__tittle">الكورسات المتاحة </h2>
            <img src="assets/images/line.png" alt="image" />
          </div> */}
          <div className="row">
            {/* {populerCourses.map((item) => <CourseCard key={item.id} course={item} />)} */}
            {course.courses.length == 0 ? (
              <>
                <div className="col text-center">
                  <h3>سوف يتم اضافة الكورسات قريبا</h3>
                </div>
              </>
            ) : (
              <>
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                  spaceBetween={50}
                  slidesPerView={1}
                  rewind
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                  }}
                  navigation={course.courses.length < 4 ? false : true}
                  // Responsive breakpoints
                  breakpoints={{
                    // when window width is >= 480px
                    300: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                      draggable: true,
                    },
                    350: {
                      slidesPerView: 1.5,
                      spaceBetween: 10,
                      draggable: true,
                    },
                    // when window width is >= 640px
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1000: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    1200: {
                      slidesPerView: 4,
                      spaceBetween: 50,
                    },
                  }}
                >
                  {course.courses.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className=" m-1 course__item mb-30">
                          <div className="course__thumb">
                            <Link to={`/single/${item._id}`}>
                              <img
                                src={`${process.env.REACT_APP_VERSEL_API}/${item.image}`}
                                alt={item.name.ar}
                              />
                            </Link>
                          </div>
                          <div className="course__inner">
                            <h3 className="react-course-title">
                              <Link to={`/single/${item._id}`}>
                                {item.name.ar}
                              </Link>
                            </h3>
                            <div className="course__card-icon d-flex align-items-center">
                              <div className="react__user">
                                {item.price / 100} جنية
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </>
            )}
          </div>
          {course.courses.length != 0 && (
            <>
              <div className="text-center">
                <Link to={"/courses"} className="view-courses">
                  مشاهدة باقي الكورسات
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-arrow-right"
                  >
                    <line x1={5} y1={12} x2={19} y2={12} />
                    <polyline points="12 5 19 12 12 19" />
                  </svg>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
function CoursesCardEnglish({ course }) {
  return (
    <>
      <div className="popular__course__area pt---50 pb---50 ">
        <div className="container">
          {/* <div className="react__title__section text-center">
            <h2 className="react__tittle">Watch Our Courses</h2>
            <img src="assets/images/line.png" alt="image" />
          </div> */}
          <div className="row">
            {/* {populerCourses.map((item) => <CourseCard key={item.id} course={item} />)} */}
            {course.courses.length == 0 ? (
              <>
                <div className="col text-center">
                  <h3>We Will Add Courses Soon</h3>
                </div>
              </>
            ) : (
              <>
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                  spaceBetween={50}
                  slidesPerView={1}
                  rewind
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                  }}
                  navigation={course.courses.length < 4 ? false : true}
                  // Responsive breakpoints
                  breakpoints={{
                    // when window width is >= 480px
                    300: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                      draggable: true,
                    },
                    350: {
                      slidesPerView: 1.5,
                      spaceBetween: 10,
                      draggable: true,
                    },
                    // when window width is >= 640px
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1000: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    1200: {
                      slidesPerView: 4,
                      spaceBetween: 50,
                    },
                  }}
                >
                  {course.courses.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className=" m-1 course__item mb-30">
                          <div className="course__thumb">
                            <Link to={`/single/${item._id}`}>
                              <img
                                src={`${process.env.REACT_APP_VERSEL_API}/${item.image}`}
                                alt={item.name.en}
                              />
                            </Link>
                          </div>
                          <div className="course__inner">
                            <h3 className="react-course-title">
                              <Link to={`/single/${item._id}`}>
                                {item.name.en}
                              </Link>
                            </h3>
                            <div className="course__card-icon d-flex align-items-center">
                              <div className="course__card-icon--1">
                                {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-users"
                              >
                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                <circle cx={9} cy={7} r={4} />
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                              </svg>
                              <span>77 Student</span> */}
                              </div>
                              <div className="react__user">
                                {" "}
                                {item.price / 100} EG
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </>
            )}
          </div>
          {course.courses.length != 0 && (
            <>
              <div className="text-center">
                <Link to={"/courses"} className="view-courses">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-arrow-right"
                  >
                    <line x1={5} y1={12} x2={19} y2={12} />
                    <polyline points="12 5 19 12 12 19" />
                  </svg>
                  Watch All Courses
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
