import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LogoutFunction from "../services/LogoutFunction";

export const paymentPaymobApi = createAsyncThunk(
  "getAllCourese",
  async (courseId) => {
    const tokenData = JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/payment/pay/${courseId}`,
      {
        method: "POST",
        headers: {
          Authorization:
            `Bearer ${tokenData}`,
            "Content-Type":"application/json"
        },
        body:JSON.stringify({
          "pay_type": "card"
        })
      }
    )
      .then((response) => response.json())
      .then((resulet) => {
        console.log(resulet);
        return resulet;
      })
      .catch((error) => {
        console.log(error);

        return error;
      });
    return request;
  }
);

const paymentPaymobSlicer = createSlice({
  name: "payment/paymob",
  initialState: {
    loading: false,
    url: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(paymentPaymobApi.pending, (state) => {
      state.loading = true;
    
    });
    builder.addCase(paymentPaymobApi.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload);
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        LogoutFunction();
      }
      if (action.payload.iframe_url) {
        state.url = action.payload;
        state.error = null;
      } else {
        state.uel = null;
        state.error = action.payload.message;
      }
    });
    builder.addCase(paymentPaymobApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default paymentPaymobSlicer.reducer;
